import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core';

// Material icons
import {
  DashboardOutlined as DashboardIcon,
  PeopleOutlined as PeopleIcon,
  ReceiptOutlined as ReceiptIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon,
  LockOpenOutlined as LockOpenIcon,
  TextFields as TextFieldsIcon,
  ImageOutlined as ImageIcon,
  InfoOutlined as InfoIcon,
  AccountBoxOutlined as AccountBoxIcon,
  SettingsOutlined as SettingsIcon
} from '@material-ui/icons';

// Component styles
import styles from './styles';

class Sidebar extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
    };

    this.isAuthorizedUser = this.isAuthorizedUser.bind(this)
  }

  componentDidMount() {
    const email = this.props.authUser
    this.setState({email})
  }

  isAuthorizedUser() {
    if (this.state.email == 'san.lee@amemobile.ca2') {
      return true
    } else {
      return false
    }
  }

  render() {
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link
            className={classes.logoLink}
            to="#"
          >
            {
              // <img
              //   alt="Brainalytica logo"
              //   className={classes.logoImage}
              //   // src="/images/logos/brainalytica_logo.svg"
              // />
            }
            <Typography
              className={classes.nameText}
              variant="h6"
            >
              AME Mobile
            </Typography>
          </Link>
        </div>
        {
          // <Divider className={classes.logoDivider} />
          // <div className={classes.profile}>
          //
          //   // <Link to="/account">
          //   //   <Avatar
          //   //     alt="Roman Kutepov"
          //   //     className={classes.avatar}
          //   //     src="/images/avatars/avatar_1.png"
          //   //   />
          //   // </Link>
          //   // <Typography
          //   //   className={classes.nameText}
          //   //   variant="h6"
          //   // >
          //   //   Roman Kutepov
          //   // </Typography>
          //   // <Typography
          //   //   className={classes.bioText}
          //   //   variant="caption"
          //   // >
          //   //   Brain Director
          //   // </Typography>
          // </div>
        }
        <Divider className={classes.profileDivider} />
        <List
          component="div"
          disablePadding
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/schedules"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Schedules"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/images"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Images"
            />
          </ListItem>

          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/dashboard"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Dashboard"
              />
            </ListItem>
          }
          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/users"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Users"
              />
            </ListItem>
          }
          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/products"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ShoppingBasketIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Products"
              />
            </ListItem>
          }
          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/sign-in"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <LockOpenIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Authentication"
              />
            </ListItem>
          }
          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/typography"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <TextFieldsIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Typography"
              />
            </ListItem>
          }
          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/icons"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Icons and Images"
              />
            </ListItem>
          }
          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/account"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Account"
              />
            </ListItem>
          }
          { this.isAuthorizedUser() &&
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/settings"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Settings"
              />
            </ListItem>
          }
        </List>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader className={classes.listSubheader}>
              Support
            </ListSubheader>
          }
        >
          <ListItem
            className={classes.listItem}
            component="a"
            href="https://www.amemobile.ca/#contact"
            target="_blank"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Customer support"
            />
          </ListItem>
        </List>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  authUser: PropTypes.string.isRequired
};

export default withStyles(styles)(Sidebar);
