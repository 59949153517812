import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

// Shared helpers
import { getInitials } from 'helpers';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

class ReceiptsTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      selected: null,
      selectedReceipts: [],
      rowsPerPage: 10,
      page: 0
    };

    this.onClickRow = this.onClickRow.bind(this)
  }

  // state = {
  //   selected: null,
  //   selectedReceipts: [],
  //   rowsPerPage: 10,
  //   page: 0
  // };

  handleSelectAll = event => {
    const { receipts, onSelect } = this.props;

    let selectedReceipts;

    if (event.target.checked) {
      selectedReceipts = receipts.map(user => user.id);
    } else {
      selectedReceipts = [];
    }

    this.setState({ selectedReceipts });

    onSelect(selectedReceipts);
  };

  handleSelectOne = (event, id) => {
    // console.log(event);
    const { onSelect } = this.props;
    const { selectedReceipts } = this.state;

    const selectedIndex = selectedReceipts.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedReceipts, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedReceipts.slice(1));
    } else if (selectedIndex === selectedReceipts.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedReceipts.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedReceipts.slice(0, selectedIndex),
        selectedReceipts.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedReceipts: newSelectedUsers });

    onSelect(newSelectedUsers);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onClickRow = (event, id) => {
    // console.log('onClickRow: ', event);
    const { onShowDetails } = this.props;
    // console.log('id: ', id);
    onShowDetails(id)
  }

  render() {
    const { classes, className, receipts } = this.props;
    const { activeTab, selectedReceipts, rowsPerPage, page } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Checkbox
                      checked={selectedReceipts.length === receipts.length}
                      color="primary"
                      indeterminate={
                        selectedReceipts.length > 0 &&
                        selectedReceipts.length < receipts.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receipts
                  .map(receipt => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={receipt.id}
                      selected={selectedReceipts.indexOf(receipt.id) !== -1}
                      ref='row'
                    >
                      <TableCell className={classes.tableCell}>
                        <div className={classes.tableCellInner}>
                          <Checkbox
                            checked={selectedReceipts.indexOf(receipt.id) !== -1}
                            color="primary"
                            value="true"
                            onChange={(event) => this.handleSelectOne(event, receipt.id)}
                          />
                        </div>
                      </TableCell>
                      <TableCell onClick={(event) => this.onClickRow(event, receipt.id)} className={classes.tableCell}>
                        <Typography
                          className={classes.nameText}
                          variant="body1"
                        >
                          {receipt.name}
                        </Typography>
                      </TableCell>
                      <TableCell onClick={(event) => this.onClickRow(event, receipt.id)} className={classes.tableCell}>
                        {receipt.date}
                      </TableCell>
                      <TableCell onClick={(event) => this.onClickRow(event, receipt.id)} className={classes.tableCell}>
                        {receipt.category}
                      </TableCell>
                      <TableCell onClick={(event) => this.onClickRow(event, receipt.id)} className={classes.tableCell}>
                        {receipt.amount}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {receipt.status}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            component="div"
            count={receipts.length}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

ReceiptsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  receipts: PropTypes.array.isRequired
};

ReceiptsTable.defaultProps = {
  receipts: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

export default withStyles(styles)(ReceiptsTable);
