export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  groupStyle: {
    width: '80%',
    margin: '10px',
    overflowy: 'scroll'
  },
  modal: {
    width: '80%'
  },
  portletButton: {
    flex: 1,
    textAlign: 'right',
    alignItems: 'right',
    justifyContent: 'right',
    paddingRight: theme.spacing(3),
  },
  portletFooter: {
    flex: 1,
    textAlign: 'right',
    alignItems: 'right',
    justifyContent: 'right',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  draggableItemHorizontal: {
    height: '260px',
    padding: '10px',
    lineHeight: '50px',
    textAlign: 'center',
    /* width : 200px, */
    display: 'block',
    backgroundColor: '#fff',
    outline: '0',
    border: '1px solid rgba(0,0,0,.125)',
    marginRight: '4px',
  }
});
