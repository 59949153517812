import firebase from 'firebase';
import moment from 'moment';

export const updateReceiptData = (key, category, date, name, amount, status, modified, email, callback) => {
  // console.log('updateReceiptData in data: ', key, category, date, name, amount, status, modified, email);
  var databaseRef = firebase.database().ref('Receipts/');
  databaseRef.child(key).update({
    category: category,
    date: date,
    name: name,
    amount: amount,
    status: status,
    modifiedBy: email,
    modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss')
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      if(!isEmpty(modified)) {
        // Object is NOT empty
        Object.assign(modified, { modifiedBy: email });
        Object.assign(modified, { modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss') });
        addUpdateHistory(key, modified)
      }
      callback('success')
    }
  })
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const addUpdateHistory = (key, modified) => {
  const databaseRef = firebase.database().ref("Receipts/" + key + "/modified/");
  return new Promise((resolve, reject) => {
    databaseRef.push(modified)
    .then((data) => {
      // console.log('data ' , data)
      resolve({
        success: data
      });
    })
    .catch((error) => {
      console.log('error ' , error)
      reject({
        error: 'data save failed'
      });
    })
  });
}

export const deleteReceiptData = (key, email, callback) => {
  var databaseRef = firebase.database().ref('Receipts/');
  databaseRef.child(key).update({
    status: 'deleted',
    modifiedBy: email,
    modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss')
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      callback('success')
    }
  })
}

export const getReceiptDataByKey = (key, callback) => {
  var databaseRef = firebase.database().ref('Receipts/');
  var query = databaseRef.child(key);
  // console.log(query);
  query.once('value', (snapshot) => {
    let data = snapshot.val();
    let result = {
      id: key,
      email: data.email,
      filename: data.filename,
      name: data.name,
      date: data.date,
      category: data.category,
      amount: data.amount,
      status: data.status,
      fullText: data.fullText
    }
    callback(result)
  })
  .catch(err => {
    callback('error')
    console.log('Error getting receipt by id', err);
  });
}

export const getReceiptData = (email, callback) => {
  // console.log('email in data: ', email);
  var databaseRef = firebase.database().ref('Receipts/');
  var query = databaseRef.orderByChild('email').equalTo(email);
  var results = []
  console.log(query);
  query.once('value', (snapshot) => {
    let data = snapshot.val();
    for (let result in data) {
      if ( data[result].status != 'deleted' ) {
        results.push({
          id: result,
          email: data[result].email,
          filename: data[result].filename,
          name: data[result].name,
          date: data[result].date,
          category: data[result].category,
          amount: data[result].amount,
          status: data[result].status,
          fullText: data[result].fullText
        })
      }
    }
    // console.log(results)
    callback(results)
  })
  .catch(err => {
    console.log('Error getting receipts', err);
  });

  // query.once('value', (snapshot) => {
  //   // snapshot.forEach(rec => {
  //   //   console.log(rec.name, '=>', rec.data());
  //   // });
  //   console.log(snapshot.val())
  //   return snapshot
  // })
  // .catch(err => {
  //   console.log('Error getting documents', err);
  // });
}

export const getTestData = () => {
  let json = `{
      'language': 'en',
      'textAngle': 0,
      'orientation': 'Up',
      'regions': [
        {
          'boundingBox': '920,472,899,862',
          'lines': [
            {
              'boundingBox': '920,472,880,264',
              'words': [
                {
                  'boundingBox': '920,472,880,264',
                  'text': 'JYSK'
                }
              ]
            },
            {
              'boundingBox': '952,779,867,76',
              'words': [
                {
                  'boundingBox': '952,790,182,65',
                  'text': 'BED'
                },
                {
                  'boundingBox': '1168,807,25,25',
                  'text': '•'
                },
                {
                  'boundingBox': '1226,784,230,67',
                  'text': 'BATH'
                },
                {
                  'boundingBox': '1488,800,26,24',
                  'text': '•'
                },
                {
                  'boundingBox': '1551,779,268,63',
                  'text': 'HOME'
                }
              ]
            },
            {
              'boundingBox': '1060,1130,327,60',
              'words': [
                {
                  'boundingBox': '1060,1132,220,58',
                  'text': 'London,'
                },
                {
                  'boundingBox': '1322,1130,65,55',
                  'text': 'ON'
                }
              ]
            },
            {
              'boundingBox': '990,1196,596,64',
              'words': [
                {
                  'boundingBox': '990,1208,66,51',
                  'text': '50'
                },
                {
                  'boundingBox': '1094,1200,162,59',
                  'text': 'North'
                },
                {
                  'boundingBox': '1290,1204,196,56',
                  'text': 'Centre'
                },
                {
                  'boundingBox': '1521,1196,65,58',
                  'text': 'RD'
                }
              ]
            },
            {
              'boundingBox': '972,1270,634,64',
              'words': [
                {
                  'boundingBox': '972,1279,100,51',
                  'text': 'GST'
                },
                {
                  'boundingBox': '1109,1270,497,64',
                  'text': '#895587061RTOOO'
                }
              ]
            }
          ]
        },
        {
          'boundingBox': '559,1422,1064,570',
          'lines': [
            {
              'boundingBox': '953,1422,529,65',
              'words': [
                {
                  'boundingBox': '953,1466,11,15',
                  'text': '.'
                },
                {
                  'boundingBox': '1016,1422,156,65',
                  'text': '(519)'
                },
                {
                  'boundingBox': '1216,1424,266,58',
                  'text': '645A414'
                }
              ]
            },
            {
              'boundingBox': '564,1423,304,64',
              'words': [
                {
                  'boundingBox': '564,1427,60,53',
                  'text': 'Te'
                },
                {
                  'boundingBox': '636,1423,16,56',
                  'text': 'I'
                },
                {
                  'boundingBox': '663,1423,205,64',
                  'text': 'ephone'
                }
              ]
            },
            {
              'boundingBox': '1012,1501,337,62',
              'words': [
                {
                  'boundingBox': '1012,1501,337,62',
                  'text': '43.3.76249'
                }
              ]
            },
            {
              'boundingBox': '559,1502,406,62',
              'words': [
                {
                  'boundingBox': '559,1502,271,62',
                  'text': 'Transact'
                },
                {
                  'boundingBox': '844,1502,121,61',
                  'text': 'ion:'
                }
              ]
            },
            {
              'boundingBox': '559,1584,312,70',
              'words': [
                {
                  'boundingBox': '559,1584,312,70',
                  'text': 'Date/time'
                }
              ]
            },
            {
              'boundingBox': '1013,1584,337,69',
              'words': [
                {
                  'boundingBox': '1013,1584,337,69',
                  'text': '08/07/2019'
                }
              ]
            },
            {
              'boundingBox': '562,1669,242,64',
              'words': [
                {
                  'boundingBox': '562,1669,242,64',
                  'text': 'Cashier'
                }
              ]
            },
            {
              'boundingBox': '958,1669,225,62',
              'words': [
                {
                  'boundingBox': '958,1687,12,44',
                  'text': ':'
                },
                {
                  'boundingBox': '1016,1669,167,62',
                  'text': 'Jaime'
                }
              ]
            },
            {
              'boundingBox': '1459,1914,164,62',
              'words': [
                {
                  'boundingBox': '1459,1914,164,62',
                  'text': 'f)rice'
                }
              ]
            },
            {
              'boundingBox': '572,1923,378,69',
              'words': [
                {
                  'boundingBox': '572,1923,378,69',
                  'text': 'Description'
                }
              ]
            }
          ]
        },
        {
          'boundingBox': '1759,1912,188,59',
          'lines': [
            {
              'boundingBox': '1759,1912,188,59',
              'words': [
                {
                  'boundingBox': '1759,1912,188,59',
                  'text': 'Amount'
                }
              ]
            }
          ]
        },
        {
          'boundingBox': '576,2155,1344,816',
          'lines': [
            {
              'boundingBox': '576,2155,1210,81',
              'words': [
                {
                  'boundingBox': '576,2175,136,60',
                  'text': 'RIVA'
                },
                {
                  'boundingBox': '752,2167,232,69',
                  'text': 'Laundry'
                },
                {
                  'boundingBox': '1025,2170,199,62',
                  'text': 'Hamper'
                },
                {
                  'boundingBox': '1263,2159,352,63',
                  'text': '54x34x45cm,'
                },
                {
                  'boundingBox': '1662,2155,124,59',
                  'text': 'Brcr'
                }
              ]
            },
            {
              'boundingBox': '1436,2232,484,66',
              'words': [
                {
                  'boundingBox': '1436,2240,15,58',
                  'text': 'I'
                },
                {
                  'boundingBox': '1499,2238,59,59',
                  'text': 'EA'
                },
                {
                  'boundingBox': '1596,2237,29,57',
                  'text': '@'
                },
                {
                  'boundingBox': '1662,2232,192,66',
                  'text': '$24.99'
                },
                {
                  'boundingBox': '1891,2232,29,56',
                  'text': 'G'
                }
              ]
            },
            {
              'boundingBox': '658,2315,860,78',
              'words': [
                {
                  'boundingBox': '658,2336,131,57',
                  'text': 'Item'
                },
                {
                  'boundingBox': '824,2333,227,59',
                  'text': 'number:'
                },
                {
                  'boundingBox': '1071,2315,447,77',
                  'text': '[31&137-1050]'
                }
              ]
            },
            {
              'boundingBox': '1565,2399,192,65',
              'words': [
                {
                  'boundingBox': '1565,2399,192,65',
                  'text': '$24.99'
                }
              ]
            },
            {
              'boundingBox': '658,2414,370,63',
              'words': [
                {
                  'boundingBox': '658,2416,158,61',
                  'text': 'Total'
                },
                {
                  'boundingBox': '862,2414,166,60',
                  'text': 'value'
                }
              ]
            },
            {
              'boundingBox': '1631,2480,160,64',
              'words': [
                {
                  'boundingBox': '1631,2480,160,64',
                  'text': '$0.00'
                }
              ]
            },
            {
              'boundingBox': '662,2485,700,72',
              'words': [
                {
                  'boundingBox': '662,2499,131,58',
                  'text': 'RIVA'
                },
                {
                  'boundingBox': '830,2492,233,62',
                  'text': 'HAMPERS'
                },
                {
                  'boundingBox': '1101,2490,28,56',
                  'text': '2'
                },
                {
                  'boundingBox': '1168,2485,95,61',
                  'text': 'for'
                },
                {
                  'boundingBox': '1301,2485,61,57',
                  'text': '40'
                }
              ]
            },
            {
              'boundingBox': '598,2561,955,75',
              'words': [
                {
                  'boundingBox': '598,2577,131,57',
                  'text': 'RIVA'
                },
                {
                  'boundingBox': '768,2571,229,65',
                  'text': 'Laundry'
                },
                {
                  'boundingBox': '1268,2561,285,65',
                  'text': '54X34X45,'
                }
              ]
            },
            {
              'boundingBox': '612,2654,145,69',
              'words': [
                {
                  'boundingBox': '612,2702,11,21',
                  'text': ','
                },
                {
                  'boundingBox': '668,2654,89,63',
                  'text': 'poo'
                }
              ]
            },
            {
              'boundingBox': '669,2733,387,67',
              'words': [
                {
                  'boundingBox': '669,2740,126,60',
                  'text': 'Item'
                },
                {
                  'boundingBox': '833,2733,223,65',
                  'text': 'number:'
                }
              ]
            },
            {
              'boundingBox': '1569,2808,197,69',
              'words': [
                {
                  'boundingBox': '1569,2808,197,69',
                  'text': '$24.99'
                }
              ]
            },
            {
              'boundingBox': '665,2818,364,67',
              'words': [
                {
                  'boundingBox': '665,2820,155,65',
                  'text': 'Total'
                },
                {
                  'boundingBox': '867,2818,162,65',
                  'text': 'value'
                }
              ]
            },
            {
              'boundingBox': '1576,2891,220,75',
              'words': [
                {
                  'boundingBox': '1576,2891,220,75',
                  'text': '($9.98)'
                }
              ]
            },
            {
              'boundingBox': '662,2898,601,73',
              'words': [
                {
                  'boundingBox': '662,2907,131,64',
                  'text': 'RIVA'
                },
                {
                  'boundingBox': '830,2904,232,66',
                  'text': 'HAMPERS'
                },
                {
                  'boundingBox': '1101,2902,28,61',
                  'text': '2'
                },
                {
                  'boundingBox': '1169,2898,94,66',
                  'text': 'for'
                }
              ]
            }
          ]
        },
        {
          'boundingBox': '584,3246,408,586',
          'lines': [
            {
              'boundingBox': '589,3246,403,67',
              'words': [
                {
                  'boundingBox': '589,3251,30,62',
                  'text': 'G'
                },
                {
                  'boundingBox': '658,3248,95,64',
                  'text': 'GSI'
                },
                {
                  'boundingBox': '794,3246,131,63',
                  'text': '5.00'
                },
                {
                  'boundingBox': '963,3247,29,62',
                  'text': '%'
                }
              ]
            },
            {
              'boundingBox': '586,3334,270,64',
              'words': [
                {
                  'boundingBox': '586,3337,31,61',
                  'text': 'G'
                },
                {
                  'boundingBox': '654,3335,100,63',
                  'text': 'HST'
                },
                {
                  'boundingBox': '793,3334,63,62',
                  'text': 'ON'
                }
              ]
            },
            {
              'boundingBox': '584,3595,336,66',
              'words': [
                {
                  'boundingBox': '584,3600,198,61',
                  'text': 'Amount'
                },
                {
                  'boundingBox': '823,3595,97,65',
                  'text': 'due'
                }
              ]
            },
            {
              'boundingBox': '586,3768,131,64',
              'words': [
                {
                  'boundingBox': '586,3768,131,64',
                  'text': 'VISA'
                }
              ]
            }
          ]
        },
        {
          'boundingBox': '1370,3062,518,771',
          'lines': [
            {
              'boundingBox': '1370,3062,503,73',
              'words': [
                {
                  'boundingBox': '1370,3062,258,71',
                  'text': 'Subtotal'
                },
                {
                  'boundingBox': '1675,3064,198,71',
                  'text': '$40.00'
                }
              ]
            },
            {
              'boundingBox': '1712,3237,166,69',
              'words': [
                {
                  'boundingBox': '1712,3237,166,69',
                  'text': '$2.00'
                }
              ]
            },
            {
              'boundingBox': '1712,3324,167,70',
              'words': [
                {
                  'boundingBox': '1712,3324,167,70',
                  'text': '$3.20'
                }
              ]
            },
            {
              'boundingBox': '1683,3584,202,72',
              'words': [
                {
                  'boundingBox': '1683,3584,202,72',
                  'text': '$45.20'
                }
              ]
            },
            {
              'boundingBox': '1686,3761,202,72',
              'words': [
                {
                  'boundingBox': '1686,3761,202,72',
                  'text': '$45.20'
                }
              ]
            }
          ]
        }
      ]
    }`
  return json
}
