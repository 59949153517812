import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../../firebase';

// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Grid,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';

// Material icons
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Shared components
import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';

// Shared services
import { getImages, setImages } from 'services/image';

// Service methods
// const signIn = () => {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve(true);
//     }, 1500);
//   });
// };

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignIn extends Component {
  state = {
    values: {
      email: '',
      password: ''
    },
    touched: {
      email: false,
      password: false
    },
    errors: {
      email: null,
      password: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleFBSignIn = () => {
    try {
      this.setState({ isLoading: true });

      this.props.firebase
        .doSignInWithFacebook()
        .then(() => {
          this.successLogin()
        })
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      });
    }
  };

  handleGoogSignIn = () => {
    try {
      this.setState({ isLoading: true });

      this.props.firebase
        .doSignInWithGoogle()
        .then((authData) => {
          // console.log("authData: ", authData.credential.idToken, authData);
          // TODO: check email
          let email = authData.user.email
          // console.log("authData: ", email, authData);
          this.successLogin(email)
        })
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      });
    }
  };

  handleEmailSignIn = () => {
    try {
      const { values } = this.state;

      this.setState({ isLoading: true });

      this.props.firebase
        .doSignInWithEmailAndPassword(values.email, values.password)
        .then(() => {
          this.successLogin(values.email)
        })
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      });
    }
  };

  successLogin = async (email) => {
    const { history } = this.props;
    // check initial images
    const { images, imagesTotal } = await getImages(10, email);
    if (imagesTotal < 1) {
      setImages(email, (result) => {
        // console.log('successLogin set default images: ', result);
      })
    }

    this.setState({ ...INITIAL_STATE });
    localStorage.setItem('isAuthenticated', true);
    history.push('/schedules');
  }

  render() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;

    const showEmailError = touched.email && errors.email;
    const showPasswordError = touched.password && errors.password;

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          {
            // <Grid
            //   className={classes.quoteWrapper}
            //   item
            //   lg={5}
            // >
            //   <div className={classes.quote}>
            //     <div className={classes.quoteInner}>
            //       <Typography
            //         className={classes.quoteText}
            //         variant="h1"
            //       >
            //         Hella narwhal Cosby sweater McSweeney's, salvia kitsch before
            //         they sold out High Life.
            //       </Typography>
            //       <div className={classes.person}>
            //         <Typography
            //           className={classes.name}
            //           variant="body1"
            //         >
            //           Takamaru Ayako
            //         </Typography>
            //         <Typography
            //           className={classes.bio}
            //           variant="body2"
            //         >
            //           Manager at inVision
            //         </Typography>
            //       </div>
            //     </div>
            //   </div>
            // </Grid>
          }
          <Grid
            className={classes.content}
            item
            xs={12}
          >
            <div className={classes.content}>
              {
                // <div className={classes.contentHeader}>
                //   <IconButton
                //     className={classes.backButton}
                //     onClick={this.handleBack}
                //   >
                //     <ArrowBackIcon />
                //   </IconButton>
                // </div>
              }
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <Typography
                    className={classes.title}
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    className={classes.subtitle}
                    variant="body1"
                  >
                    Sign in with social media
                  </Typography>
                  {
                    // <Button
                    //   className={classes.facebookButton}
                    //   color="primary"
                    //   onClick={this.handleFBSignIn}
                    //   size="large"
                    //   variant="contained"
                    // >
                    //   <FacebookIcon className={classes.facebookIcon} />
                    //   Login with Facebook
                    // </Button>
                  }
                  <Button
                    className={classes.googleButton}
                    onClick={this.handleGoogSignIn}
                    size="large"
                    variant="contained"
                  >
                    <GoogleIcon className={classes.googleIcon} />
                    Login with Google
                  </Button>
                  <Typography
                    className={classes.sugestion}
                    variant="body1"
                  >
                    or login with email address
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label="Email address"
                      name="email"
                      onChange={event =>
                        this.handleFieldChange('email', event.target.value)
                      }
                      type="text"
                      value={values.email}
                      variant="outlined"
                    />
                    {showEmailError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.email[0]}
                      </Typography>
                    )}
                    <TextField
                      className={classes.textField}
                      label="Password"
                      name="password"
                      onChange={event =>
                        this.handleFieldChange('password', event.target.value)
                      }
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    {showPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant="body2"
                      >
                        {errors.password[0]}
                      </Typography>
                    )}
                  </div>
                  {submitError && (
                    <Typography
                      className={classes.submitError}
                      variant="body2"
                    >
                      {submitError}
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!isValid}
                      onClick={this.handleEmailSignIn}
                      size="large"
                      variant="contained"
                    >
                      Sign in now
                    </Button>
                  )}
                  <Typography
                    className={classes.signUp}
                    variant="body1"
                  >
                    Don't have an account?{' '}
                    <Link
                      className={classes.signUpUrl}
                      to="/sign-up"
                    >
                      Sign up
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withFirebase,
  withStyles(styles)
)(SignIn);
