import firebase from 'firebase'
import generateRandomID from 'uuid/v4'

export const uploadFiles = (files, loginEmail, callback) => {
  const generateRandomFilename = (): string => generateRandomID()
  const storageRef = firebase.storage().ref("images")
  let results = []
  files.forEach(file => {
    let filenameToUse = generateRandomFilename()
    let image = storageRef.child(filenameToUse)
    image.put(file).then((snapshot) => {
      // // call abbyy ocr
      // analyzeReceipt(loginEmail, filenameToUse, file)
      // call azure ocr
      image.getDownloadURL().then((url) => {
        if (url) {
          let result = {
            email: loginEmail,
            url: url,
            filename: filenameToUse
          }
          // analyzeReceiptAzure(result)
          callback(result)
        }
      })
    })
  });
};

function analyzeReceipt(email, filename, file) {
  const API_URL='http://localhost:5239/receipt'
  var formData = new FormData()
  formData.append('email', email)
  formData.append('filename', filename)
  formData.append('file', file)

  fetch(API_URL, {
     method: 'POST',
     body: formData
   })
   .then(response => console.log('Response from abbyy:', response))
   .catch(error => console.error('Error:', error))
   .then(response => console.log('Success:', JSON.stringify(response)));
}

function analyzeReceiptAzure(payload) {
  console.log('analyzeReceiptAzure: ', payload);

  const API_URL = 'http://192.168.1.5:5239/receipt' // 'http://localhost:5239/receipt' //

  const fetchOptions = {
    method: 'POST',
    // mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }

  fetch(API_URL, fetchOptions).then(function (response) {
    console.log(response.json())
  }).then(function (json) {
    console.log(json)
  })
}
