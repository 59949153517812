import React, { Component } from 'react';
import { compose } from 'recompose';

// Authorization
import { withAuthorization } from '../../session'

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { ReconcileProfile, ReconcileDetails, ReconcileFullText } from './components';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class Reconcile extends Component {
  state = { tabIndex: 0 };

  render() {
    const { classes, schedule, closeModal, saveDetails } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={4}
          >
            <ReconcileProfile schedule={schedule} />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <ReconcileFullText schedule={schedule} />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <ReconcileDetails schedule={schedule} closeModal={closeModal} saveDetails={saveDetails} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Reconcile.propTypes = {
  classes: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  saveDetails: PropTypes.func
};

Reconcile.defaultProps = {
  closeModal: () => {},
  saveDetails: () => {}
};

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withStyles(styles))(Reconcile);
