import React, { useContext, useReducer } from "react";
import ReactDOM from "react-dom";

import PropTypes from 'prop-types';
import classNames from 'classnames';

import Store from "./context";
import reducer from "./reducer";

import { usePersistedContext, usePersistedReducer } from "./usePersist";

import TodoList from "./components/TodoList";
import TodoForm from "./components/TodoForm";

// function Todo() {
const Todo = props => {
  const { classes, className, emails, addEmail, removeEmail } = props;
  // create a global store to store the state
  const globalStore = usePersistedContext(useContext(Store), "state");

  // `todos` will be a state manager to manage state.
  const [state, dispatch] = usePersistedReducer(
    useReducer(reducer, globalStore),
    "state" // The localStorage key
  );

  state.todos = emails

  // console.log('Todo: ', state.todos, emails);

  return (
    // State.Provider passes the state and dispatcher to the down
    <Store.Provider value={{ state, dispatch }}>
      <TodoForm
        addEmail={addEmail}
      />
      <TodoList
        todo={emails}
        removeEmail={removeEmail}
      />
    </Store.Provider>
  );
}

Todo.propTypes = {
  emails: PropTypes.array,
  addEmail: PropTypes.func,
  removeEmail: PropTypes.func
};

Todo.defaultProps = {
  addEmail: () => {},
  removeEmail: () => {}
};

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);

export default (Todo);
