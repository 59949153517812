import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Avatar,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination
} from '@material-ui/core';

// Shared helpers
import { getInitials } from 'helpers';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
  Todo
} from 'components';

// Component styles
import styles from './styles';

import { addScheduleEmail, deleteScheduleEmail } from 'services/schedule';

import '@trendmicro/react-modal/dist/react-modal.css';
import Modal from '@trendmicro/react-modal';

class SchedulesTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      scheduleId: null,
      emails: [],
      selected: null,
      selectedSchedules: [],
      rowsPerPage: 10,
      page: 0,
      sharesModalOpen: false
    };

    this.onClickRow = this.onClickRow.bind(this)
    this.addEmail = this.addEmail.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
  }

  handleSelectAll = event => {
    const { schedules, onSelect } = this.props;

    let selectedSchedules = [];

    if (event.target.checked) {
      for (let i = 0; i < schedules.length; i++) {
        if (!schedules[i].sharedBy) {
          selectedSchedules.push(schedules[i].id);
        }
      }
      // selectedSchedules = schedules.map(schedule => schedule.id);
    } else {
      selectedSchedules = [];
    }

    this.setState({ selectedSchedules });

    onSelect(selectedSchedules);
  };

  handleSelectOne = (event, id) => {
    console.log(event);
    const { onSelect } = this.props;
    const { selectedSchedules } = this.state;

    const selectedIndex = selectedSchedules.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedSchedules, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedSchedules.slice(1));
    } else if (selectedIndex === selectedSchedules.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedSchedules.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedSchedules.slice(0, selectedIndex),
        selectedSchedules.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedSchedules: newSelectedUsers });

    onSelect(newSelectedUsers);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onClickRow = (event, id) => {
    const { onShowDetails } = this.props;
    onShowDetails(id)
  }

  openSharesModal(event, id, emails) {
    let emailObjs = []
    if (emails) {
      emailObjs = Object.values(emails)
    }
    // console.log('openSharesModal: ', id, emailObjs);
    this.setState({
      scheduleId: id,
      emails: emailObjs,
      sharesModalOpen: true
    });
  }

  closeSharesModal() {
    this.setState({sharesModalOpen: false});
  }

  addEmail(email) {
    const { callback } = this.props;
    const { scheduleId } = this.state;
    addScheduleEmail(scheduleId, email, (result) => {
      // console.log('addEmail schedule: ', scheduleId, email);
      callback();
    })
  }

  removeEmail(email) {
    const { callback } = this.props;
    const { scheduleId } = this.state;
    deleteScheduleEmail(scheduleId, email, (result) => {
      // console.log('removeEmail: ', scheduleId, email);
      callback();
    })
  }

  render() {
    const { classes, className, schedules } = this.props;
    const { activeTab, selectedSchedules, rowsPerPage, page } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Checkbox
                      checked={selectedSchedules.length > 0}
                      color="primary"
                      indeterminate={
                        selectedSchedules.length > 0 &&
                        selectedSchedules.length < schedules.length
                      }
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Cues</TableCell>
                  <TableCell align="left">Shared?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedules.map(schedule => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={schedule.id}
                    selected={selectedSchedules.indexOf(schedule.id) !== -1}
                    ref='row'
                  >
                    <TableCell className={classes.tableCell}>
                      <div className={classes.tableCellInner}>
                        <Checkbox
                          disabled={schedule.sharedBy ? true : false}
                          disableRipple={schedule.sharedBy ? true : false}
                          checked={selectedSchedules.indexOf(schedule.id) !== -1}
                          color="primary"
                          value="true"
                          onChange={(event) => this.handleSelectOne(event, schedule.id)}
                        />
                      </div>
                    </TableCell>
                    <TableCell onClick={(event) => this.onClickRow(event, schedule.id)} className={classes.tableCell}>
                      <Typography
                        className={classes.nameText}
                        variant="body1"
                      >
                        {schedule.title}
                      </Typography>
                    </TableCell>
                    <TableCell onClick={(event) => this.onClickRow(event, schedule.id)} className={classes.tableCell}>
                      {moment(schedule.created).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell onClick={(event) => this.onClickRow(event, schedule.id)} className={classes.tableCellNumber}>
                      {schedule.size}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                    <div className={classes.portletButton}>
                    { schedule.sharedBy &&
                      <TableCell onClick={(event) => this.onClickRow(event, schedule.id)} className={classes.tableCellNumber}>
                        By {schedule.sharedBy}
                      </TableCell>
                    }
                    { !schedule.sharedBy &&
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(event) => this.openSharesModal(event, schedule.id, schedule.emails)}
                      >
                        {schedule.shares}
                      </Button>
                    }
                    </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          { // pagenation
            // <TablePagination
            //   backIconButtonProps={{
            //     'aria-label': 'Previous Page'
            //   }}
            //   component="div"
            //   count={schedules.length}
            //   nextIconButtonProps={{
            //     'aria-label': 'Next Page'
            //   }}
            //   onChangePage={this.handleChangePage}
            //   onChangeRowsPerPage={this.handleChangeRowsPerPage}
            //   page={page}
            //   rowsPerPage={rowsPerPage}
            //   rowsPerPageOptions={[5, 10, 25]}
            // />
          }
        </PortletContent>

        <Modal
          show={this.state.sharesModalOpen}
          showOverlay={this.state.sharesModalOpen}
          onClose={this.closeSharesModal.bind(this)}
        >
          <Portlet
            className={rootClassName}
          >
            <PortletHeader>
              <PortletLabel
                subtitle=''
                title="Share Schedule"
              />
            </PortletHeader>
            <PortletContent>
              <div className={classes.fieldCenter}>
                <Todo
                  emails={this.state.emails}
                  addEmail={this.addEmail}
                  removeEmail={this.removeEmail}
                />
              </div>
              {
                // <div className={classes.field}>
                //   <TextField
                //     className={classes.textField}
                //     helperText="Description for this cue"
                //     label="Title"
                //     margin="dense"
                //     // onChange={(evt) => this.handleChange(evt, 'title')}
                //     required
                //     // value={this.state.formControls.title.value || ''}
                //     variant="outlined"
                //   />
                // </div>
              }
            </PortletContent>
            <PortletFooter
              className={classes.portletFooter}
            >
              <Typography variant="subtitle1">Share schedule via emails.</Typography>
            {
              // <Button
              //   color="primary"
              //   variant="contained"
              //   // onClick={this.saveData.bind(this)}
              // >
              //   Save
              // </Button>
            }
            </PortletFooter>
          </Portlet>
        </Modal>

      </Portlet>
    );
  }
}

SchedulesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  schedules: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  callback: PropTypes.func
};

SchedulesTable.defaultProps = {
  schedules: [],
  onSelect: () => {},
  onShowDetails: () => {},
  callback: () => {}
};

export default withStyles(styles)(SchedulesTable);
