import React, { Component } from 'react';
import { compose } from 'recompose';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { getReceipts, getReceiptById, updateReceipt, deleteReceipt } from 'services/receipt';

// Custom components
import { ReceiptsToolbar, ReceiptsTable } from './components';

// Component styles
import styles from './style';

// Authorization
import { withAuthorization } from '../../session';

// custom functions
import { uploadFiles } from 'rest'
import Modal from 'react-modal';
import Reconcile from '../Reconcile';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(200, 200, 200, 0.75)'
  },
  content: {
    position: 'absolute',
    top: '140px',
    left: '140px',
    right: '140px',
    bottom: '140px',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px'
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

class Receipts extends Component {
  constructor() {
    super()
    this.signal = true;

    this.state = {
      isLoading: false,
      limit: 10,
      email: '',
      receipts: [],
      receipt: null,
      selectedReceipts: [],
      error: null,
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showDetails = this.showDetails.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
    this.getReceipts();
  }

  async getReceipts() {
    try {
      this.setState({ isLoading: true });

      const { limit, email } = this.state;

      // console.log('email in call ', email);

      const { receipts } =  await getReceipts(limit, email);

      if (this.signal) {
        this.setState({
          isLoading: false,
          receipts
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  componentDidMount() {
    this.signal = true;
    const email = this.props.firebase.auth.currentUser.email
    this.setState({email})

    // const token = this.props.firebase.auth.currentUser
    // console.log('token: ', token);

    var self = this
    setTimeout(function () {
      self.getReceipts();
    }, 100);

  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedReceipts => {
    this.setState({ selectedReceipts });
    var self = this
    setTimeout(function () {
      // console.log('selectedReceipts: ', self.state.selectedReceipts);
    }, 100);
  };

  deleteReceipts() {
    let email = this.state.email
    // console.log('deleteReceipts: ', email);
    let ids = []
    for (let i = 0; i < this.state.selectedReceipts.length; i++) {
      ids.push(this.state.selectedReceipts[i])
    }
    this.deleteReceiptsFromFirebase(ids, email)
    // console.log('deleteReceipts: ', ids);
  }

  async deleteReceiptsFromFirebase(ids, email) {
    for (let i = 0; i < ids.length; i++) {
      await deleteReceipt(ids[i], email)
    }
    this.setState({ isLoading: true });
    var self = this
    setTimeout(function () {
      self.getReceipts()
    }, 3000);
  }

  addNewReceipt = (files) => {
    let email = this.state.email
    // console.log('addNewReceipt: ', files, email);
    uploadFiles(files, email)
    this.setState({ isLoading: true });
    var self = this
    setTimeout(function () {
      self.getReceipts()
    }, 3000);
  }

  async showDetails(id) {
    // modal reconcile page
    const { receipt }  = await getReceiptById(id)
    // console.log('showDetails id: ', id, receipt);
    this.setState({modalIsOpen: true, receipt});
  }

  async saveDetails(newReceipt, callback) {
    let email = this.state.email
    // console.log('saveDetails: ', newReceipt, email);
    let result = await updateReceipt(newReceipt, email)
    // console.log('result of saveDetails: ', result, newReceipt.id);
    let updatedReceipt = await getReceiptById(newReceipt.id)
    // console.log('updated: ', updatedReceipt);
    callback(updatedReceipt)
  }

  renderReceipts() {
    const { classes } = this.props;
    const { isLoading, receipts, error } = this.state;

    if (isLoading) { // need to be modal
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (receipts.length === 0) {
      return <Typography variant="h6">There are no receipts</Typography>;
    }

    return (
      <ReceiptsTable
        //
        onShowDetails={this.showDetails}
        onSelect={this.handleSelect}
        receipts={receipts}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedReceipts, receipt } = this.state;

    return (
      <DashboardLayout title="Receipts">
        <div className={classes.root}>

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Review Receipt"
          >
            <Reconcile receipt={receipt} closeModal={this.closeModal} saveDetails={this.saveDetails.bind(this)} />
          </Modal>

          <ReceiptsToolbar selectedReceipts={selectedReceipts} onAdd={this.addNewReceipt} onDelete={this.deleteReceipts.bind(this)} />
          <div className={classes.content}>{this.renderReceipts()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

Receipts.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withStyles(styles))(Receipts);
