import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Avatar, Typography, Button, LinearProgress, GridList, GridListTile } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// custom modules
import firebase from 'firebase'

import Gallery from 'react-grid-gallery';

class ImageList extends Component {
  constructor(props){
    super(props);

    this.state = {
        selectAllChecked: false
    };

    this.onSelectImage = this.onSelectImage.bind(this);
    this.getSelectedImages = this.getSelectedImages.bind(this);
  }

  onSelectImage (index, image) {
    var images = this.state.images.slice();
    var img = images[index];

    for(var i = 0; i < this.state.images.length; i++) {
      if (img != images[i]) {
        images[i].isSelected = false
      } else {
        images[i].isSelected = true
      }
    }

    // if(img.hasOwnProperty("isSelected"))
    //     img.isSelected = !img.isSelected;
    // else
    //     img.isSelected = true;

    this.setState({
      images: images
    });

    // if(this.allImagesSelected(images)){
    //   this.setState({
    //       selectAllChecked: true
    //   });
    // } else {
    //   this.setState({
    //       selectAllChecked: false
    //   });
    // }
  }

  getSelectedImages () {
    var selected = [];
    for(var i = 0; i < this.state.images.length; i++)
      if(this.state.images[i].isSelected == true)
        selected.push(i);
    return selected;
  }

  render() {
    const { classes, className, schedule, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const storageRef = firebase.storage().ref("schedules")
    // storageRef.child(schedule.filename).getDownloadURL().then(function(url) {
    //   // `url` is the download URL for 'images/stars.jpg'
    //   // This can be downloaded directly:
    //   var xhr = new XMLHttpRequest();
    //   xhr.responseType = 'blob';
    //   xhr.onload = function(event) {
    //     var blob = xhr.response;
    //   };
    //   xhr.open('GET', url);
    //   xhr.send();
    //
    //   // Or inserted into an <img> element:
    //   var img = document.getElementById('scheduleImage');
    //   img.src = url;
    // }).catch(function(error) {
    //   // Handle any errors
    // });

    // return (
    //   <GridList cellHeight={160} className={classes.gridList} cols={1}>
    //     {this.state.images.map((tile) => (
    //       <GridListTile key={tile.src} cols={tile.cols || 1}>
    //         <img src={tile.src} alt={tile.caption} />
    //       </GridListTile>
    //     ))}
    //   </GridList>
    // )

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title="Image List"
          />
        </PortletHeader>
        <PortletContent>
          <div style={{height: 300, overflow: 'scroll'}}>
            <Gallery
              images={this.state.images}
              onSelectImage={this.onSelectImage}
              showLightboxThumbnails={true}/>
          </div>
        </PortletContent>
        <PortletFooter>
          show text button
        </PortletFooter>
      </Portlet>
    );
  }
}



ImageList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired
};

export default withStyles(styles)(ImageList);
