import firebase from 'firebase';
import moment from 'moment';

export const setDefaultImages = (email, callback) => {
  var databaseRef = firebase.database().ref('Images/');
  var query = databaseRef.orderByChild('email').equalTo('san.lee@amemobile.ca');
  // console.log(query);
  query.once('value', (snapshot) => {
    let data = snapshot.val();
    for (let result in data) {
      if ( data[result].status != 'deleted' && data[result].id > 0 ) {
        addImageData(email, data[result].title, data[result].url, () => {
          // console.log('addImageData', data[result].title, data[result].url);
        })
      }
    }
    // console.log('setDefaultImages: ', data.length)
    callback(data.length)
  })
  .catch(err => {
    callback('error')
    console.log('Error setDefaultImages');
  });
}

export const addImageData = (email, title, url, callback) => {
  // console.log('addImageData: ', email, title, url);
  var databaseRef = firebase.database().ref('Images/');
  databaseRef.push({
    email,
    title,
    url
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      callback('success')
    }
  })
}

export const deleteImageData = (key, email, callback) => {
  var databaseRef = firebase.database().ref('Images/');
  databaseRef.child(key).update({
    status: 'deleted',
    modifiedBy: email,
    modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss')
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      callback('success')
    }
  })
}

export const updateImageData = (key, title, email, callback) => {
  var databaseRef = firebase.database().ref('Images/');
  databaseRef.child(key).update({
    title: title,
    status: 'updated',
    modifiedBy: email,
    modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss')
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      callback('success')
    }
  })
}

// src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
// thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
// thumbnailWidth: 320,
// thumbnailHeight: 174,
// isSelected: true,
// caption: "After Rain (Jeshu John - designerspics.com)"

export const getImageData = (email, callback) => {
  // console.log('email in data: ', email);
  var databaseRef = firebase.database().ref('Images/');
  var query = databaseRef.orderByChild('email').equalTo(email);
  var results = []
  // console.log(query);
  query.once('value', (snapshot) => {
    let data = snapshot.val();
    for (let result in data) {
      if ( data[result].status != 'deleted' ) {
        results.push({
          id: result,
          email: data[result].email,
          caption: data[result].title,
          src: data[result].url,
          thumbnail: data[result].url,
          thumbnailWidth: 200,
          thumbnailHeight: 200,
        })
      }
    }
    // console.log('getImageData: ', results)
    callback(results)
  })
  .catch(err => {
    callback('error')
    // console.log('Error getting images', err);
  });

  // query.once('value', (snapshot) => {
  //   // snapshot.forEach(rec => {
  //   //   console.log(rec.name, '=>', rec.data());
  //   // });
  //   console.log(snapshot.val())
  //   return snapshot
  // })
  // .catch(err => {
  //   console.log('Error getting documents', err);
  // });
}

//
//   return new Promise((resolve, reject) => {
//     databaseRef.push({
//       email,
//       title,
//       url
//     })
//     .then((data) => {
//       // console.log('data ', data)
//       resolve({
//         success: data
//       });
//     })
//     .catch((error) => {
//       // console.log('error ', error)
//       reject({
//         error: 'data save failed'
//       });
//     })
//   });
// }





export default [
  {
    id: 'DEV738089',
    title: 'Dropbox',
    description:
      'Dropbox is a file hosting service that offers cloud storage, file synchronization, a personal cloud.',
    imageUrl: '/images/products/product_1.png',
    totalDownloads: '594',
    price: '10',
    status: 'published',
    createdAt: '27/03/2019'
  },
  {
    id: 'DEV795381',
    title: 'Medium Corporation',
    description:
      'Medium is an online publishing platform developed by Evan Williams, and launched in August 2012.',
    imageUrl: '/images/products/product_2.png',
    totalDownloads: '625',
    price: '27',
    status: 'archived',
    createdAt: '31/03/2019'
  },
  {
    id: 'DEV774585',
    title: 'Slack',
    description:
      'Slack is a cloud-based set of team collaboration tools and services, founded by Stewart Butterfield.',
    imageUrl: '/images/products/product_3.png',
    totalDownloads: '857',
    price: '20',
    status: 'published',
    createdAt: '03/04/2019'
  },
  {
    id: 'DEV654476',
    title: 'Lyft',
    description:
      'Lyft is an on-demand transportation company based in San Francisco, California.',
    imageUrl: '/images/products/product_4.png',
    totalDownloads: '406',
    price: '28',
    status: 'draft',
    createdAt: '04/04/2019'
  },
  {
    id: 'DEV958347',
    title: 'GitHub',
    description:
      'GitHub is a web-based hosting service for version control of code using Git.',
    imageUrl: '/images/products/product_5.png',
    totalDownloads: '835',
    price: '32',
    status: 'published',
    createdAt: '04/04/2019'
  },
  {
    id: 'DEV773559',
    title: 'Squarespace',
    description:
      'Squarespace provides software as a service for website building and hosting. Headquartered in NYC.',
    imageUrl: '/images/products/product_6.png',
    totalDownloads: '835',
    price: '32',
    status: 'published',
    createdAt: '04/04/2019'
  }
];
