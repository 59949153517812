import {
  setDefaultImages,
  addImageData,
  getImageData,
  // getImageDataByKey,
  updateImageData,
  deleteImageData
} from 'data/images';

export const setImages = (email, callback) => {
  // console.log('update image: ', image.status, email);
  setDefaultImages(email, (result) => {
    callback(result)
  })
};

export const addImage = (image, callback) => {
  // console.log('update image: ', image.status, email);
  addImageData(
    image.email,
    image.title,
    image.url,
    (result) => {
      // console.log('in addImage service: ', result);
      if (result == 'success') {
        callback(result)
      } else {
        callback('error')
      }
    });
};

export const getImages = (limit = 10, email) => {
  return new Promise(resolve => {
    getImageData(email, (images) => {
      console.log('images ', images)
      resolve({
        images: images,
        imagesTotal: images.length
      });
    })
  });
};

export const deleteImage = (id, email, callback) => {
  deleteImageData(id, email, (result) => {
    if (result == 'success') {
      callback({
        result: result
      });
    } else {
      callback({
        error: 'Image not updated'
      });
    }
  })
};

// key, title, status, modified, email, callback
export const updateImage = (image, email, callback) => {
  updateImageData(
    image.id,
    image.title,
    email,
    (result) => {
    if (result == 'success') {
      callback({
        result: result
      });
    } else {
      callback({
        error: 'Image not updated'
      });
    }
  })
};

// return new Promise((resolve, reject) => {
//   addScheduleData(
//     email,
//     title,
//     (result) => {
//     if (result == 'success') {
//       resolve({
//         result: result
//       });
//     } else {
//       reject({
//         error: 'Schedule not added'
//       });
//     }
//   })
// });

// function lookupImage() {
//   let images = getImageData()
//   const userCopy = JSON.parse(JSON.stringify(images));
//   // const userOrders = userCopy.orders.map(id =>
//   //   orders.find(order => order.id === id)
//   // );
//   // const userMoneySpent = userCopy.orders.reduce(
//   //   (total, order) => total + order.amount,
//   //   0
//   // );
//   //
//   // userCopy.orders = userOrders;
//   // userCopy.moneySpent = userMoneySpent;
//
//   return userCopy;
// }
//
// export const getImages = (limit = 6) => {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve({
//         images: images.slice(0, limit),
//         imagesTotal: images.length
//       });
//     }, 700);
//   });
// };
//

//
// export const getImageById = id => {
//   return new Promise((resolve, reject) => {
//     getImageDataByKey(id, (image) => {
//       // console.log(image);
//       if (image) {
//         resolve({
//           image: image
//         });
//       } else {
//         reject({
//           error: 'Image not found'
//         });
//       }
//     })
//   });
// };
//
//
// export const updateImage = (image, email) => {
//   // console.log('update image: ', image.status, email);
//   return new Promise((resolve, reject) => {
//     updateImageData(
//       image.id,
//       image.category,
//       image.date,
//       image.name,
//       image.amount,
//       image.status,
//       image.modified,
//       email,
//       (result) => {
//       if (result == 'success') {
//         resolve({
//           result: result
//         });
//       } else {
//         reject({
//           error: 'Image not updated'
//         });
//       }
//     })
//   });
// };
