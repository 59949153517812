export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  gallery: {
    padding: theme.spacing(2),
    marginTop: '10px',
    marginBottom: '10px',
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  pagination: {
    display: 'none',
    // marginTop: '24px',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end'
  }
});
