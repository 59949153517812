import React, { Component } from 'react';
import {DropzoneArea, DropzoneDialog} from 'material-ui-dropzone'
// import Modal from './components/Modal';
import '@trendmicro/react-modal/dist/react-modal.css';
import Modal from '@trendmicro/react-modal';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, IconButton, TextField } from '@material-ui/core';

// Material icons
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';

// Shared components
import {
  DisplayMode,
  SearchInput,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import axios from 'axios';

// Component styles
import styles from './styles';

class SchedulesToolbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      files: [],
      showDropZone: false,
      open: false,
      formControls: {
        title: {
          value: ''
        },
      }
    };
  }

  handleFileChange(files){
    this.setState({
      files: files
    });
  }

  handleClose() {
      this.setState({
          open: false
      });
  }
  handleOpen() {
      this.setState({
          open: true,
      });
  }

  handleChange(event, name) {
    const value = event.target.value;

    // console.log('name: ', name, ' value: ', value);

    this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: {
          ...this.state.formControls[name],
          value
        }
      }
    });
  };

  handleTest() {
    const API_URL = 'https://www.youtube.com/'

    const fetchOptions = {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }

    fetch(API_URL, fetchOptions).then(function (response) {
      // console.log('response', API_URL, response)
    }).then(function (json) {
      // console.log('json', json)
    })
  }

  handleDeleteReceipts() {
    const { onDelete } = this.props
    onDelete()
  }

  saveData() {
    this.handleClose()
    const { onAdd } = this.props;
    let newSchedule = {
      title: this.state.formControls.title.value
    }
    onAdd(newSchedule.title)
  }

  render() {
    const { classes, className, selectedSchedules, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <span className={classes.spacer} />
          {selectedSchedules.length > 0 && (
            <IconButton
              className={classes.deleteButton}
              onClick={this.handleDeleteReceipts.bind(this)}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <Button
            className={classes.importButton}
            size="small"
            variant="outlined"
            onClick={this.handleTest.bind(this)}
          >
            <ArrowDownwardIcon className={classes.importIcon} /> Import
          </Button>
          <Button
            className={classes.exportButton}
            size="small"
            variant="outlined"
            onClick={ () => { this.setState({showDropZone: !this.state.showDropZone}) }}
          >
            <ArrowUpwardIcon className={classes.exportIcon} />
            Export
          </Button>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={this.handleOpen.bind(this)}
          >
            Add
          </Button>
        </div>
        { this.state.showDropZone && (
          <DropzoneArea
            onChange={this.handleFileChange.bind(this)}
          />
        )}

        <Modal
          show={this.state.open}
          showOverlay={this.state.open}
          onClose={this.handleClose.bind(this)}
        >
          <Portlet
            {...rest}
            className={rootClassName}
          >
            <PortletHeader>
              <PortletLabel
                subtitle=""
                title="New Schedule"
              />
            </PortletHeader>
            <PortletContent noPadding>
              <form
                autoComplete="off"
                noValidate
              >
                <div className={classes.field}>
                  <TextField
                    className={classes.textField}
                    helperText="new schedule title"
                    label="Title"
                    margin="dense"
                    onChange={(evt) => this.handleChange(evt, 'title')}
                    required
                    value={this.state.formControls.title.value || ''}
                    variant="outlined"
                  />
                </div>
              </form>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
            {
              // <Button
              //   color="primary"
              //   variant="contained"
              //   onClick={this.handleClose.bind(this)}
              // >
              //   Close
              // </Button>
            }
              <Button
                color="primary"
                variant="contained"
                onClick={ () => this.saveData() }
              >
                Save
              </Button>

            </PortletFooter>
          </Portlet>
        </Modal>
        { // search box and grid/list button
          // <div className={classes.row}>
          //   <SearchInput
          //     className={classes.searchInput}
          //     placeholder="Search schedule"
          //   />
          //   <span className={classes.spacer} />
          //   <DisplayMode mode="list" />
          // </div>
        }
      </div>
    );
  }
}

SchedulesToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedSchedules: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func
};

SchedulesToolbar.defaultProps = {
  selectedSchedules: [],
  onAdd: () => {},
  onDelete: () => {}
};

export default withStyles(styles)(SchedulesToolbar);
