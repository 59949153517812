import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Button, LinearProgress, TextField } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// custom
import moment from 'moment';

import {DropzoneArea} from 'material-ui-dropzone'

import InputNumber from 'react-input-number';

import Gallery from 'react-grid-gallery';

// Shared services
import { uploadFiles } from 'rest'
import { getImages, addImage, deleteImage, updateImage } from 'services/image';
import { addScheduleCue, updateScheduleCue, deleteScheduleCue } from 'services/schedule';

import Resizer from 'react-image-file-resizer';

class ImageEditor extends Component {
  signal = true;

  constructor() {
    super()

    this.state = {
      id: '',
      url: '',
      source: 'Library',
      selectedImage: null,
      images: [],
      imagesTotal: 0,
      limit: 6,
      formControls: {
        title: {
          value: ''
        },
        min: {
          value: '0'
        },
        sec: {
          value: '0'
        },
        date: {
          value: moment().format('YYYY-MM-DD')
        }
      },
    }
  }

  componentDidMount() {
    this.signal = true;
    const { image } = this.props;

    if (image) {
      let duration = image.duration
      let min = Math.floor(duration / 60)
      let sec = duration % 60

      this.setState({
        selectedImage: image,
        url: image.src,
        formControls: {
          title: {
            value: image.caption
          },
          min: {
            value: min
          },
          sec: {
            value: sec
          }
        }
      })
    }

    this.loadImages()
  }

  componentWillUnmount() {
    this.signal = false;
  }

  async loadImages() {
    try {
      this.setState({ isLoading: true });

      const { email } = this.props;

      let limit = 10 // not used yet

      const { images, imagesTotal } = await getImages(limit, email);

      console.log('loadImages: ', email, images);

      if (this.signal) {
        this.setState({
          isLoading: false,
          images,
          imagesTotal,
          limit
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  handleChange(event, name) {
    const value = event.target.value;

    // console.log('name: ', name, ' value: ', value);

    this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: {
          ...this.state.formControls[name],
          value
        }
      }
    });
  };

  handleFileChange(files){
    // console.log(URL.createObjectURL(files[0]))
    this.setState({
      files: files,
      url: URL.createObjectURL(files[0])
    });
  }

  deleteData() {
    const { email, image, schedule, callback } = this.props;
    let key = image.id
    if (schedule) {
      deleteScheduleCue(schedule.id, key, email, (result) => {
        // console.log('deleteScheduleCue: ', result);
        callback();
      })
    } else {
      deleteImage(key, email, (result) => {
        // console.log('delete image: ', result);
        callback();
      })
    }
  }

  saveData() {
    const { email, schedule, image, lastorder, callback } = this.props;
    const { files, formControls, selectedImage } = this.state;

    if (selectedImage) {
      if (schedule) {
        if (image) { // update exisiting cue
          let cue = {
            id: selectedImage.id,
            title:formControls.title.value,
            duration: parseInt(formControls.min.value) * 60 + parseInt(formControls.sec.value),
            order: lastorder
          }
          updateScheduleCue(schedule, cue, email, (result) => {
            // console.log('updateScheduleCue: ', result);
            callback();
          })
        } else { // add new cue
          let cueImage = {
            // email: email,
            title: selectedImage.caption,
            url: selectedImage.src
          }
          let cue = {
            image: cueImage,
            title: formControls.title.value,
            duration: parseInt(formControls.min.value) * 60 + parseInt(formControls.sec.value),
            order: lastorder
          }
          // console.log('addScheduleCue: ', schedule, cue);
          addScheduleCue(schedule.id, cue, (result) => {
            callback();
          })
        }
      } else { // editing for image alone
        let updatedImage = {
          id: selectedImage.id,
          email: email,
          title: formControls.title.value,
          url: selectedImage.src
        }
        updateImage(updatedImage, email, (result) => {
          // console.log('updateImage: ', result);
          callback();
        })
      }
    } else { // new image from uploading
      let title = this.state.formControls.title.value
      console.log('saveData: ', files, title, email);
      // resize file
      Resizer.imageFileResizer(
          files[0],
          512,
          512,
          'PNG',
          100,
          0,
          file => {
            // console.log('imageFileResizer: ', title, file);
            let files = []
            files.push(file)
            uploadFiles(files, email, (result) => {
              let image = {
                email: email,
                title: title,
                url: result.url
              }
              addImage(image, (result) => {
                // console.log('addImage: ', result);
                if (schedule) {
                  let cue = {
                    image: image,
                    title:formControls.title.value,
                    duration: parseInt(formControls.min.value) * 60 + parseInt(formControls.sec.value),
                    order: lastorder
                  }
                  // console.log('addScheduleCue: ', schedule, cue);
                  addScheduleCue(schedule.id, cue, (result) => {
                    callback();
                  })
                } else {
                  callback();
                }
              })
            })
          },
          'blob'
      );
    }
  }

  checkShowDropzone() {
    const { url, source } = this.state;
    if (url != '') {
      return 0
    } else {
      if (source == 'New') {
        return 2
      } else {
        return 1
      }
    }
  }

  toggleSourceButton() {
    let source = this.state.source;
    if (source == 'New') {
      source = 'Library'
    } else {
      source = 'New'
    }
    this.setState({ source });
  }

  onSelectImage (index) {
    var images = this.state.images.slice();
    var img = images[index];
    var image = JSON.parse(JSON.stringify(img))

    // for(var i = 0; i < this.state.images.length; i++) {
    //   if (img != images[i]) {
    //     images[i].isSelected = false
    //   } else {
    //     images[i].isSelected = true
    //   }
    // }

    // console.log('onSelectImage: ', image.src);

    this.setState({
        selectedImage: img,
        url: image.src,
        formControls: {
          title: {
            value: image.caption
          },
          min: {
            value: 0
          },
          sec: {
            value: 0
          }
        }
    });
  }

  thumbnailStyle() {
    return {
      width: '150px',
      height: '150px'
    };
  }

  render() {
    const { classes, className, email, from, image, schedule, onClose, callback, ...rest } = this.props;
    const { source, images, url, selectedImage } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle=''
            title="Image Cue"
          />
        </PortletHeader>
        { !this.checkShowDropzone() == 0 && schedule != null &&
          <div className={classes.portletButton}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => this.toggleSourceButton()}
            >
              {source}
            </Button>
          </div>
        }
        <PortletContent noPadding>
          <div className={classes.field}>
            { this.checkShowDropzone() == 2 &&
              <div style={{width: 500, height: 400, overflow: 'scroll'}}>
                <Gallery
                  images={images}
                  thumbnailStyle={this.thumbnailStyle}
                  tileViewportStyle={this.thumbnailStyle}
                  onClickThumbnail={this.onSelectImage.bind(this)}
                  showLightboxThumbnails={false}/>
              </div>
            }
            { this.checkShowDropzone() == 1 &&
              <DropzoneArea
                onChange={this.handleFileChange.bind(this)}
                showPreviewsInDropzone={false}
                showPreviews={false}
                dropzoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
              />
            }
            { this.checkShowDropzone() == 0 &&
              <div className={classes.fieldCenter}>
                <img src={url} alt="" width="400" height="400" />
              </div>
            }
            {
              // <DropzoneDialog
              //     open={this.state.open}
              //     onSave={this.handleSave.bind(this)}
              //     acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
              //     showPreviews={true}
              //     maxFileSize={5000000}
              //     onClose={this.handleClose.bind(this)}
              //     showFileNamesInPreview={true}
              // />
            }
          </div>
          <div className={classes.field}>
          {
            // <ImageUploading
            //   onChange={this.onChange}
            //   maxNumber={2}
            //   multiple={false}
            //   maxFileSize={1000}
            //   acceptType={["jpg", "gif", "png"]}
            // >
            //   {({ imageList, onImageUpload, onImageRemoveAll }) => (
            //     // write your building UI
            //     <div>
            //       <button onClick={onImageUpload}>Upload image</button>
            //       <button onClick={onImageRemoveAll}>Remove image</button>
            //
            //       {imageList.map((image) => (
            //         <div key={image.key}>
            //           <img src={image.dataURL} alt="" width="100" />
            //           <button onClick={image.onUpdate}>Update</button>
            //           <button onClick={image.onRemove}>Remove</button>
            //         </div>
            //       ))}
            //     </div>
            //   )}
            // </ImageUploading>
          }
          </div>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              helperText="Description for this cue"
              label="Title"
              margin="dense"
              onChange={(evt) => this.handleChange(evt, 'title')}
              required
              value={this.state.formControls.title.value || ''}
              variant="outlined"
            />
          </div>
          { from == 'schedule' &&
            <div className={classes.nField}>
              <TextField
                className={classes.numberField}
                helperText="Duration in min"
                label="Min"
                margin="dense"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 60 } }}
                onChange={(evt) => this.handleChange(evt, 'min')}
                required
                value={this.state.formControls.min.value || '0'}
                variant="outlined"
              />
              <TextField
                className={classes.numberField}
                helperText="Duration in sec"
                label="Sec"
                margin="dense"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 60 } }}
                onChange={(evt) => this.handleChange(evt, 'sec')}
                required
                value={this.state.formControls.sec.value || '0'}
                variant="outlined"
              />
            </div>
          }
        </PortletContent>
        <PortletFooter
          className={classes.portletFooter}
          style={{justifyContent: image != null ? 'space-between' : 'flex-end'}}
        >
        <div style={{justifyContent: image != null ? 'space-between' : 'flex-end'}}>
          { image != null &&
            <div className={classes.deleteButton}>
            <Button
              display="inline"
              color="primary"
              variant="contained"
              onClick={this.deleteData.bind(this)}
            >
              Delete
            </Button>
            </div>
          }
          <div className={classes.saveButton}>
          <Button
            display="inline"
            color="primary"
            variant="contained"
            onClick={this.saveData.bind(this)}
          >
            Save
          </Button>
          </div>
          </div>
        </PortletFooter>
      </Portlet>
    );
  }
}

ImageEditor.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  from: PropTypes.string,
  email: PropTypes.string.isRequired,
  schedule: PropTypes.object,
  image: PropTypes.object,
  lastorder: PropTypes.number,
  onClose: PropTypes.func,
  callback: PropTypes.func
};

ImageEditor.defaultProps = {
  onClose: () => {},
  callback: () => {}
};

export default withStyles(styles)(ImageEditor);
