import React, { useContext } from "react";
import Store from "../context";
import { TodoHeader } from "./TodoHeader";

// export default function TodoList() {
export default function TodoList(props) {

  const { state, dispatch } = useContext(Store);

  // console.log('list: ', state);

  function handleTodoRemove(email) {
    // console.log('handleTodoRemove: ', email);
    const { removeEmail } = props;
    removeEmail(email)
  }

  const pluralize = count =>
    count > 1 ? `There are ${count} shares.` : `There is ${count} share.`;

  let header =
    state.todos.length === 0 ? (
      <body></body>
    ) : (
      <TodoHeader>
        <span className="float-right">{pluralize(state.todos.length)}</span>
      </TodoHeader>
    );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <br />
            {header}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="list-group">
              {state.todos.map(t => (
                <li key={t} className="list-group-item">
                  {t}
                  <button
                    className="float-right btn btn-danger btn-sm"
                    style={{ marginLeft: 10 }}
                    onClick={() => handleTodoRemove(t)}//dispatch({ type: "REMOVE", payload: t })}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
