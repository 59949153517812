import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Avatar, Typography, Button, LinearProgress } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// custom modules
import firebase from 'firebase'

class ReconcileProfile extends Component {
  render() {
    const { classes, className, schedule, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const storageRef = firebase.storage().ref("schedules")
    // storageRef.child(schedule.filename).getDownloadURL().then(function(url) {
    //   // `url` is the download URL for 'images/stars.jpg'
    //   // This can be downloaded directly:
    //   var xhr = new XMLHttpRequest();
    //   xhr.responseType = 'blob';
    //   xhr.onload = function(event) {
    //     var blob = xhr.response;
    //   };
    //   xhr.open('GET', url);
    //   xhr.send();
    //
    //   // Or inserted into an <img> element:
    //   var img = document.getElementById('scheduleImage');
    //   img.src = url;
    // }).catch(function(error) {
    //   // Handle any errors
    // });

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title="Receipt Image"
          />
        </PortletHeader>
        <PortletContent>
          <div className={classes.details}>
            <div className={classes.info}>
              <img id='scheduleImage'
                src='/images/empty.png'
                width='100%'
                alt={schedule.title}
              />
            </div>
          </div>
        </PortletContent>
        <PortletFooter>
          show text button
        </PortletFooter>
      </Portlet>
    );
  }
}

ReconcileProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired
};

export default withStyles(styles)(ReconcileProfile);
