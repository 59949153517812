import firebase from 'firebase';
import moment from 'moment';

export const addScheduleData = (email, title, callback) => {
  // console.log('addScheduleData: ', email, title);
  var databaseRef = firebase.database().ref('Schedules/');
  // return new Promise((resolve, reject) => {
    databaseRef.push({
      email,
      title,
      createdOn: moment().format('YYYY-MM-DD HH:mm:ss')
    })
    .then((data) => {
      // console.log('data ' , data)
      callback({
        success: data
      });
    })
    .catch((error) => {
      console.log('error ' , error)
      callback({
        error: 'data save failed'
      });
    })
  // });
}

export const addScheduleEmailData = (key, email, callback) => {
  const databaseRef = firebase.database().ref("Schedules/" + key + "/emails/");
  databaseRef.push(email)
  .then((data) => {
    // console.log('data ' , data)
    callback({
      success: data
    });
  })
  .catch((error) => {
    console.log('error ' , error)
    callback({
      error: 'data save failed'
    });
  })
}

export const deleteScheduleEmailData = (scheduleKey, email, callback) => {
  var databaseRef = firebase.database().ref('Schedules/' + scheduleKey + '/emails/')
  // var query = databaseRef.orderByChild('email').equalTo(email);
  databaseRef.once('value').then(function(snapshot) {
    let data = snapshot.val();
    // console.log('first: ', data);
    for (let key in data) {
      if ( data[key] == email) {
        // console.log('matched: ', data[key]);
        var ref = firebase.database().ref('Schedules/' + scheduleKey + '/emails/' + key)
        ref.remove()
        .then(function() {
          callback('success')
        })
        .catch(function(error) {
          callback('error' + error.message)
        });
      }
    }
  })


  // databaseRef.remove()
  // .then(function() {
  //   callback('success')
  // })
  // .catch(function(error) {
  //   callback('error' + error.message)
  // });
}

export const addScheduleCueData = (key, cue, callback) => {
  const databaseRef = firebase.database().ref("Schedules/" + key + "/cues/");
  databaseRef.push(cue)
  .then((data) => {
    // console.log('data ' , data)
    callback({
      success: data
    });
  })
  .catch((error) => {
    console.log('error ' , error)
    callback({
      error: 'data save failed'
    });
  })
}

export const updateScheduleCueData = (scheduleKey, cueKey, title, duration, order, email, callback) => {
  // console.log('updateScheduleCueData in data: ', scheduleKey, cueKey, title, duration, order, email);
  var databaseRef = firebase.database().ref('Schedules/' + scheduleKey + '/cues/');
  databaseRef.child(cueKey).update({
    duration: duration,
    order: order,
    status: 'updated',
    modifiedBy: email,
    modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss')
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      updateCueImage(scheduleKey, cueKey, title, (result) => {
        callback('success')
      })
    }
  })
}

function updateCueImage(scheduleKey, cueKey, title, callback) {
  var databaseRef = firebase.database().ref('Schedules/' + scheduleKey + '/cues/' + cueKey + '/image/')
  databaseRef.update({
    title: title
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      callback('success')
    }
  })
}

export const deleteScheduleCueData = (scheduleKey, cueKey, email, callback) => {
  var databaseRef = firebase.database().ref('Schedules/' + scheduleKey + '/cues/' + cueKey)
  databaseRef.remove()
  .then(function() {
    callback('success')
  })
  .catch(function(error) {
    callback('error' + error.message)
  });
}

export const updateScheduleData = (key, title, email, callback) => {
  var databaseRef = firebase.database().ref('Schedules/');
  databaseRef.child(key).update({
    title: title,
    status: 'updated',
    modifiedBy: email,
    modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss')
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      // if(!isEmpty(modified)) {
      //   // Object is NOT empty
      //   Object.assign(modified, { modifiedBy: email });
      //   Object.assign(modified, { modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss') });
      //   addUpdateHistory(key, modified)
      // }
      callback('success')
    }
  })
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const addUpdateHistory = (key, modified) => {
  const databaseRef = firebase.database().ref("Schedules/" + key + "/modified/");
  return new Promise((resolve, reject) => {
    databaseRef.push(modified)
    .then((data) => {
      // console.log('data ' , data)
      resolve({
        success: data
      });
    })
    .catch((error) => {
      console.log('error ' , error)
      reject({
        error: 'data save failed'
      });
    })
  });
}

export const deleteScheduleData = (key, email, callback) => {
  var databaseRef = firebase.database().ref('Schedules/');
  databaseRef.child(key).update({
    status: 'deleted',
    modifiedBy: email,
    modifiedOn: moment().format('YYYY-MM-DD HH:mm:ss')
  }, function(error) {
    if (error) {
      callback('error')
    } else {
      callback('success')
    }
  })
}

export const getScheduleDataByKey = (key, callback) => {
  var databaseRef = firebase.database().ref('Schedules/');
  var query = databaseRef.child(key);
  query.once('value', (snapshot) => {
    let data = snapshot.val()

    let result = {
      id: key,
      email: data.email,
      title: data.title,
      cues: data.cues
    }

    callback(result)

  })
  .catch(err => {
    callback('error')
    console.log('Error getting schedule by id', err);
  });
}

function getScheduleCues(key, callback) {
  var databaseRef = firebase.database().ref('Schedules/');
  var query = databaseRef.child(key);
  query.once('value', (snapshot) => {
      let data = snapshot.val();
      // console.log('getScheduleCues: ', key, data.cues);
      callback(data.cues)
    }
  )
}

function returnScheduleCues(key) {
  var databaseRef = firebase.database().ref('Schedules/');
  var query = databaseRef.child(key);
  query.once('value', (snapshot) => {
      let data = snapshot.val();
      // console.log('getScheduleCues: ', key, data.cues);
      return(data.cues)
    }
  )
}

export function getScheduleData_no(email) {
  var ref = firebase.database().ref('Schedules/');
  return ref.orderByChild('email').equalTo(email).once('value').then(function(snapshot) {
    var reads = [];
    snapshot.forEach(function(childSnapshot) {
        var id = childSnapshot.key;
        console.log(id);
        // var promise = returnScheduleCues(id);
        var promise = ref.child(id).once('value').then(function(snap) {
            // The Promise was fulfilled.
        }, function(error) {
            // The Promise was rejected.
            console.error(error);
        });
        reads.push(promise);
    });
    return Promise.all(reads);
  })
}

export const getSharedSchedules = (email, callback) => {
    var databaseRef = firebase.database().ref('Schedules/');
    databaseRef.once('value').then(async function(snapshot) {
      let data = snapshot.val();
      // console.log('first: ', data);
      var schedules = [];
      for (let result in data) {
        if ( data[result].status != 'deleted' ) {
          // console.log('first: ', data[result].cues);
          let size = 0
          if (data[result].cues) {
            size = Object.keys(data[result].cues).length
          }

          let shares = 0
          if (data[result].emails) {
            shares = Object.keys(data[result].emails).length
          }

          //console.log(['joe', 'jane', 'mary'].includes('jane')); //true

          if (data[result].email == email) {
            let schedule = ({
              id: result,
              email: data[result].email,
              title: data[result].title,
              created: data[result].createdOn,
              cues: data[result].cues,
              size: size,
              shares: shares,
              emails: data[result].emails
            })
            schedules.push(schedule);
          }

          if (data[result].emails && Object.values(data[result].emails).includes(email)) {
            let schedule = ({
              id: result,
              email: data[result].email,
              title: data[result].title,
              created: data[result].createdOn,
              cues: data[result].cues,
              size: size,
              shares: 0,
              emails: [],
              sharedBy: data[result].email
            })
            schedules.push(schedule);
          }
        }
      }
      // console.log('first schedules: ', schedules);
      return (schedules)
    }, (error) =>{
      console.error(error);
    }).then((values) => {
      // console.log('after promise: ', values);
      callback(values)
    });
}

export const getScheduleData = (email, callback) => {
    var databaseRef = firebase.database().ref('Schedules/');
    var query = databaseRef.orderByChild('email').equalTo(email);
    query.once('value').then(async function(snapshot) {
      let data = snapshot.val();
      // console.log('first: ', data);
      var schedules = [];
      for (let result in data) {
        if ( data[result].status != 'deleted' ) {
          // console.log('first: ', data[result].cues);
          let size = 0
          if (data[result].cues) {
            size = Object.keys(data[result].cues).length
          }

          let shares = 0
          if (data[result].emails) {
            shares = Object.keys(data[result].emails).length
          }

          let schedule = ({
            id: result,
            email: data[result].email,
            title: data[result].title,
            created: data[result].createdOn,
            cues: data[result].cues,
            size: size,
            shares: shares,
            emails: data[result].emails
          })
          schedules.push(schedule);
        }
      }
      // console.log('first schedules: ', schedules);
      return (schedules)
    }, (error) =>{
      console.error(error);
    }).then((values) => {
      // console.log('after promise: ', values);
      callback(values)
    });
}

// export const getScheduleData = (email, callback) => {
//   var schedules = []
//   getSchedulesByEmail(email, (results) => {
//     var databaseRef = firebase.database().ref('Schedules/');
//     for (let i = 0; i < results.length; i++) {
//       var subQuery = databaseRef.child(results[i].id);
//       subQuery.once('value', (snapshotSub) => {
//         let dataSub = snapshotSub.val();
//         var size = 0
//         for (let cue in dataSub.cues) {
//           size++
//         }
//         schedules.push({
//           id: results[i].id,
//           email: results[i].email,
//           title: results[i].title,
//           created: results[i].createdOn,
//           size: size
//         })
//       })
//     }
//     console.log('getScheduleData: ', schedules);
//     callback(schedules)
//   })
//
// }
    // var databaseRef = firebase.database().ref('Schedules/');
    // var schedules = []
    // for (let result in results) { // result == key

    // }
    // console.log('getScheduleData: ', schedules);
    // callback(schedules)
//   })
//   .catch(err => {
//     console.log('Error getting documents', err);
//   });
// }
  // // console.log('email in data: ', email);
  // var databaseRef = firebase.database().ref('Schedules/');
  // var query = databaseRef.orderByChild('email').equalTo(email);
  // // console.log(query);
  // var results = []
  // query.once('value', (snapshot) => {
  //   let data = snapshot.val();
  //   for (let result in data) { // result == key
  //     if ( data[result].status != 'deleted' ) {
  //
  //       var subQuery = databaseRef.child(result);
  //       subQuery.once('value', (snapshotSub) => {
  //         let dataSub = snapshotSub.val();
  //         var size = 0
  //         for (let cue in dataSub.cues) {
  //           size++
  //         }
  //         console.log('size: ', size);

  //         console.log('getScheduleData in: ', results)
  //       })
  //     }
  //   }
  //   console.log('getScheduleData out: ', results)
  //   callback(results)
  // })
  // .catch(err => {
  //   console.log('Error getting schedules', err);
  // });

  // query.once('value', (snapshot) => {
  //   // snapshot.forEach(rec => {
  //   //   console.log(rec.name, '=>', rec.data());
  //   // });
  //   console.log(snapshot.val())
  //   return snapshot
  // })

// }
