import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Button, LinearProgress, TextField } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// custom
import moment from 'moment';

const categories = [
  {
    value: 'TBD',
    label: 'TBD'
  },
  {
    value: 'GeneralRetail',
    label: 'General Retail'
  },
  {
    value: 'office',
    label: 'Office Expenses'
  },
  {
    value: 'supplies',
    label: 'Supplies'
  },
  {
    value: 'utilities',
    label: 'Utilites'
  }
];

class Reconcile extends Component {
  constructor() {
    super()

    this.state = {
      schedule: null,
      completeness: 0,
      formControls: {
        name: {
          value: 'Provider'
        },
        date: {
          value: moment().format('YYYY-MM-DD')
        },
        category: {
          value: 'TBD'
        },
        amount: {
          value: '0'
        }
      }
    }

    this.saveData = this.saveData.bind(this)
  }

  componentDidMount() {
    const { schedule } = this.props
    this.updateReceipt(schedule)
  }

  updateReceipt(receipt) {
    // console.log(receipt);
    this.setState({
      formControls: {
        name: {
          value: receipt.name
        },
        date: {
          value: receipt.date
        },
        category: {
          value: receipt.category
        },
        amount: {
          value: receipt.amount
        }
      }
    })

    let completeness = 0
    if (receipt.category != 'TBD') {
      completeness = 100
    } else {
      completeness = 50
    }

    this.setState({completeness})
  }

  handleChange(event, name) {
    const value = event.target.value;

    console.log('name: ', name, ' value: ', value);

    this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: {
          ...this.state.formControls[name],
          value
        }
      }
    });

    // const updatedControls = {
	  //    ...this.state.formControls
    // };
    // const updatedFormElement = {
	  //    ...updatedControls[name]
    // };
    //
    // updatedFormElement.value = value;
    // // updatedFormElement.touched = true;
    // // updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
    // updatedControls[name] = updatedFormElement;
    //
    // this.setState({
    // 	formControls: updatedControls
    // });
  };

  saveData() {
    const { schedule, saveDetails } = this.props;
    // console.log('saveData:', schedule);

    let updated = {}
    if (schedule.name != this.state.formControls.name.value) {
      Object.assign(updated, { name: schedule.name });
    }
    if (schedule.date != this.state.formControls.date.value) {
      Object.assign(updated, { date: schedule.date });
    }
    if (schedule.category != this.state.formControls.category.value) {
      Object.assign(updated, { category: schedule.category });
    }
    if (schedule.amount != this.state.formControls.amount.value) {
      Object.assign(updated, { amount: schedule.amount });
    }

    let newReceipt = {
      id: schedule.id,
      name: this.state.formControls.name.value,
      date: this.state.formControls.date.value,
      category: this.state.formControls.category.value,
      amount: this.state.formControls.amount.value,
      status: schedule.status,
      modified: updated
    }
    saveDetails(newReceipt, (updatedReceipt) => {
      // console.log('saveDeatils: ', updatedReceipt.schedule);
      this.updateReceipt(updatedReceipt.schedule)
    })
  }

  render() {
    const { classes, className, closeModal, saveDetails, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="The information can be edited"
            title="Receipt Detail"
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete="off"
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                helperText="Please correct name if needed"
                label="Name"
                margin="dense"
                onChange={(evt) => this.handleChange(evt, 'name')}
                required
                value={this.state.formControls.name.value || ''}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Date"
                margin="dense"
                onChange={(evt) => this.handleChange(evt, 'date')}
                required
                value={this.state.formControls.date.value || ''}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Category"
                margin="dense"
                onChange={(evt) => this.handleChange(evt, 'category')}
                required
                select
                SelectProps={{ native: true }}
                value={this.state.formControls.category.value || ''}
                variant="outlined">
                {categories.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
              </div>
              <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Amount"
                margin="dense"
                onChange={(evt) => this.handleChange(evt, 'amount')}
                required
                value={this.state.formControls.amount.value || ''}
                variant="outlined"
              />
            </div>
          </form>
          <div className={classes.progressWrapper}>
            <Typography variant="body1">Reconcile Completeness: {this.state.completeness}%</Typography>
            <LinearProgress
              value={this.state.completeness}
              variant="determinate"
            />
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="contained"
            onClick={ () => this.saveData() }
          >
            Save
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={closeModal}
          >
            Close
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

Reconcile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  saveDetails: PropTypes.func
};

Reconcile.defaultProps = {
  closeModal: () => {},
  saveDetails: () => {}
};

export default withStyles(styles)(Reconcile);
