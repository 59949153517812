import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Avatar, Typography, Button, LinearProgress } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// custom modules
import firebase from 'firebase'

class ReconcileFullText extends Component {
  render() {
    const { classes, className, schedule, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    let completeness = 0
    // if (schedule.category != 'TBD') {
    //   completeness = 100
    // } else {
    //   completeness = 50
    // }

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title="Receipt Text"
          />
        </PortletHeader>
        <PortletContent>
          <div className={classes.details}>
          <pre>
            {schedule.fullText}
          </pre>
          </div>
        </PortletContent>
        <PortletFooter>
          hide button may go here!
        </PortletFooter>
      </Portlet>
    );
  }
}

ReconcileFullText.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired
};

export default withStyles(styles)(ReconcileFullText);
