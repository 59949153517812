import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import {
  DisplayMode,
  SearchInput,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// Material components
import { Button, TextField } from '@material-ui/core';

import '@trendmicro/react-modal/dist/react-modal.css';
import Modal from '@trendmicro/react-modal';

import { ImageEditor } from '../../../Editor/components';

class ImagesToolbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose() {
    this.setState({
        open: false
    });
  }

  afterSave() {
    const { callback } = this.props;
    this.handleClose();
    // console.log('callback from editor');
    callback()
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  render() {
    const { classes, className, email, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <span className={classes.spacer} />
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={this.handleOpen.bind(this)}
          >
            Add
          </Button>
        </div>

          <Modal
            show={this.state.open}
            showOverlay={this.state.open}
            onClose={this.handleClose.bind(this)}
          >
            <ImageEditor
              from={'images'}
              email={email}
              onClose={this.handleClose.bind(this)}
              callback={this.afterSave.bind(this)} />
          </Modal>

        {
          // <div className={classes.row}>
          //   <SearchInput
          //     className={classes.searchInput}
          //     placeholder="Search image"
          //   />
          //   <span className={classes.spacer} />
          //   <DisplayMode mode="grid" />
          // </div>
        }

      </div>
    );
  }
}

ImagesToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default withStyles(styles)(ImagesToolbar);
