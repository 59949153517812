import {
  getSharedSchedules,
  addScheduleData,
  getScheduleData,
  getScheduleDataByKey,
  updateScheduleCueData,
  updateScheduleData,
  deleteScheduleData,
  deleteScheduleCueData,
  addScheduleCueData,
  addScheduleEmailData,
  deleteScheduleEmailData,
} from 'data/schedules';

export const addSchedule = (email, title, callback) => {
  // console.log('update schedule: ', schedule.status, email);
  // return new Promise((resolve, reject) => {
    addScheduleData(
      email,
      title,
      (result) => {
      if (result == 'success') {
        callback({
          result: result
        });
      } else {
        callback({
          error: 'Schedule not added'
        });
      }
    })
  // });
};

export const addScheduleEmail = (key, email, callback) => {
  // console.log('in addScheduleEmail service: ', key, email);
  addScheduleEmailData(
    key,
    email,
    (result) => {
      // console.log('addScheduleEmail callback:', result);
      if (result == 'success') {
        callback(result)
      } else {
        callback('error')
      }
    });
}

export const deleteScheduleEmail = (scheduleKey, email, callback) => {
  deleteScheduleEmailData(
    scheduleKey,
    email,
    (result) => {
    if (result == 'success') {
      callback({
        result: result
      });
    } else {
      callback({
        error: 'Schedule not updated'
      });
    }
  })
};

export const addScheduleCue = (key, cue, callback) => {
  addScheduleCueData(
    key,
    cue,
    (result) => {
      console.log('in addScheduleCue service: ', cue);
      if (result == 'success') {
        callback(result)
      } else {
        callback('error')
      }
    });
}

function lookupSchedule() {
  let schedules = getScheduleData()
  const userCopy = JSON.parse(JSON.stringify(schedules));
  // const userOrders = userCopy.orders.map(id =>
  //   orders.find(order => order.id === id)
  // );
  // const userMoneySpent = userCopy.orders.reduce(
  //   (total, order) => total + order.amount,
  //   0
  // );
  //
  // userCopy.orders = userOrders;
  // userCopy.moneySpent = userMoneySpent;

  return userCopy;
}

export const getSchedules = (limit = 10, email) => {
  return new Promise(resolve => {
    // getScheduleData(email, (schedules) => {
    getSharedSchedules(email, (schedules) => {
      // console.log('getSchedules in service: ', JSON.stringify(schedules))
      resolve({
        schedules: schedules,
        schedulesTotal: schedules.length
      });
    })
  });
};

export const getScheduleById = id => {
  return new Promise((resolve, reject) => {
    getScheduleDataByKey(id, (schedule) => {
      // console.log('getScheduleById: ', schedule);
      if (schedule) {
        resolve({
          schedule: schedule
        });
      } else {
        reject({
          error: 'Schedule not found'
        });
      }
    })
  });
};

export const deleteSchedule = (id, email, callback) => {
  deleteScheduleData(id, email, (result) => {
    if (result == 'success') {
      callback({
        result: result
      });
    } else {
      callback({
        error: 'Schedule not updated'
      });
    }
  })
};

export const deleteScheduleCue = (scheduleKey, cueKey, email, callback) => {
  deleteScheduleCueData(
    scheduleKey,
    cueKey,
    email,
    (result) => {
    if (result == 'success') {
      callback({
        result: result
      });
    } else {
      callback({
        error: 'Schedule not updated'
      });
    }
  })
};

export const updateScheduleCue = (schedule, cue, email, callback) => {
  updateScheduleCueData(
    schedule.id,
    cue.id,
    cue.title,
    cue.duration,
    cue.order,
    email,
    (result) => {
    if (result == 'success') {
      callback({
        result: result
      });
    } else {
      callback({
        error: 'Schedule not updated'
      });
    }
  })
};

export const updateSchedule = (schedule, title, email, callback) => {
  // console.log('update schedule: ', schedule.status, email);
  // return new Promise((resolve, reject) => {
    updateScheduleData(
      schedule.id,
      title,
      email,
      (result) => {
      if (result == 'success') {
        callback({
          result: result
        });
      } else {
        callback({
          error: 'Schedule not updated'
        });
      }
    })
  // });
};
