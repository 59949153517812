import { getReceiptData, getReceiptDataByKey, updateReceiptData, deleteReceiptData } from 'data/receipts';
// import orders from 'data/orders';

function lookupReceipt() {
  let receipts = getReceiptData()
  const userCopy = JSON.parse(JSON.stringify(receipts));
  // const userOrders = userCopy.orders.map(id =>
  //   orders.find(order => order.id === id)
  // );
  // const userMoneySpent = userCopy.orders.reduce(
  //   (total, order) => total + order.amount,
  //   0
  // );
  //
  // userCopy.orders = userOrders;
  // userCopy.moneySpent = userMoneySpent;

  return userCopy;
}

export const getReceipts = (limit = 10, email) => {
  return new Promise(resolve => {
    getReceiptData(email, (receipts) => {
      // console.log('receipts ', receipts)
      resolve({
        receipts: receipts,
        receiptsTotal: receipts.length
      });
    })
  });
};

export const getReceiptById = id => {
  return new Promise((resolve, reject) => {
    getReceiptDataByKey(id, (result) => {
      // console.log(result);
      if (result) {
        resolve({
          receipt: result
        });
      } else {
        reject({
          error: 'Receipt not found'
        });
      }
    })
  });
};

export const deleteReceipt = (id, email) => {
  return new Promise((resolve, reject) => {
    deleteReceiptData(id, email, (result) => {
      if (result == 'success') {
        resolve({
          result: result
        });
      } else {
        reject({
          error: 'Receipt not updated'
        });
      }
    })
  });
};

export const updateReceipt = (receipt, email) => {
  // console.log('update receipt: ', receipt.status, email);
  return new Promise((resolve, reject) => {
    updateReceiptData(
      receipt.id,
      receipt.category,
      receipt.date,
      receipt.name,
      receipt.amount,
      receipt.status,
      receipt.modified,
      email,
      (result) => {
      if (result == 'success') {
        resolve({
          result: result
        });
      } else {
        reject({
          error: 'Receipt not updated'
        });
      }
    })
  });
};
