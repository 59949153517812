import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import classNames from 'classnames';

// Authorization
import { withAuthorization } from '../../session';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  IconButton,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Divider
} from '@material-ui/core';

// Material icons
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { getImages } from 'services/image';

// Custom components
import { ImagesToolbar, ImageCard } from './components';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

import '@trendmicro/react-modal/dist/react-modal.css';
import Modal from '@trendmicro/react-modal';

import { ImageEditor } from '../Editor/components';

import Gallery from 'react-grid-gallery';

import { Paper } from '@material-ui/core';

class ImageList extends Component {
  signal = true;

  constructor(props){
    super(props);

    this.state = {
      email: '',
      open: false,
      isLoading: false,
      limit: 6,
      images: [],
      imagesTotal: 0,
      selectedImage: null,
      error: null
    };
  }

  componentDidMount() {
    this.signal = true;
    const email = this.props.firebase.auth.currentUser.email
    this.setState({email})

    const { limit } = this.state;

    var self = this
    setTimeout(function () {
      self.loadImages(limit);
    }, 100);
  }

  componentWillMount() {
    // this.signal = true;
    //
    // const email = this.props.firebase.auth.currentUser.email
    // this.setState({email})
    //
    // const { limit } = this.state;
    //
    // console.log('will mount ', limit);
    // this.loadImages(limit);
  }

  componentWillUnmount() {
    this.signal = false;
  }

  async loadImages(limit) {
    try {
      this.setState({ isLoading: true });

      const { limit, email } = this.state;

      const { images, imagesTotal } = await getImages(limit, email);

      // console.log('try to get images', images);

      if (this.signal) {
        this.setState({
          isLoading: false,
          images,
          imagesTotal,
          limit
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  handleClose() {
    var images = this.state.images.slice();

    for(var i = 0; i < this.state.images.length; i++) {
      images[i].isSelected = false
    }

    this.setState({
        open: false
    });
  }

  // handleSave(files) {
  //   //Saving files to state for further use and closing Modal.
  //   this.setState({
  //       files: files,
  //       open: false
  //   });
  //
  //   const { onAdd } = this.props
  //
  //   onAdd(files)
  // }

  afterSave() {
    this.loadImages(10);
    this.handleClose();
    console.log('callback in ImageEditor');
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  onSelectImage (index, image) {
    var images = this.state.images.slice();
    var img = images[index];

    for(var i = 0; i < this.state.images.length; i++) {
      if (img != images[i]) {
        images[i].isSelected = false
      } else {
        images[i].isSelected = true
      }
    }

    this.setState({
        selectedImage: img,
    });

    this.handleOpen()
  }

  thumbnailStyle() {
    return {
      width: '150px',
      height: '150px'
    };
  }

  renderImages() {
    const { classes, className } = this.props;
    const { isLoading, images, email } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (images.length === 0) {
      return (
        <Typography variant="h6">There are no images available</Typography>
      );
    }

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <Gallery
            images={images}
            thumbnailStyle={this.thumbnailStyle}
            tileViewportStyle={this.thumbnailStyle}
            // onSelectImage={this.onSelectImage.bind(this)}
            onClickThumbnail={this.onSelectImage.bind(this)}
            showLightboxThumbnails={true}/>
          <Modal
            show={this.state.open}
            showOverlay={this.state.open}
            onClose={this.handleClose.bind(this)}
          >
            <ImageEditor
              from={'images'}
              email={email}
              image={this.state.selectedImage}
              onClose={this.handleClose.bind(this)}
              callback={this.afterSave.bind(this)} />
          </Modal>
        </PortletContent>
      </Portlet>
    )
  }

  render() {
    const { classes } = this.props;
    const { email } = this.state;

    return (
      <DashboardLayout title="Images">
        <div className={classes.root}>
          <ImagesToolbar
            email={email}
            callback={this.afterSave.bind(this)} />

          <div className={classes.content}>{this.renderImages()}</div>

          <div className={classes.pagination}>
            <Typography variant="caption">1-6 of 20</Typography>
            <IconButton>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton>
              <ChevronRightIcon />
            </IconButton>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

ImageList.propTypes = {
  classes: PropTypes.object.isRequired
};

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withStyles(styles))(ImageList);
