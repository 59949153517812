import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Button, LinearProgress, TextField } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

// custom
import moment from 'moment';

import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

const SortableItem = SortableElement(({value}) =>
  <li>{value}</li>
);

const SortableList = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

class ListEditor extends Component {
  constructor() {
    super()

    this.state = {
      schedule: null,
      completeness: 0,
      formControls: {
        name: {
          value: 'Provider'
        },
        date: {
          value: moment().format('YYYY-MM-DD')
        },
        category: {
          value: 'TBD'
        },
        amount: {
          value: '0'
        }
      },
      items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
    }
    this.saveData = this.saveData.bind(this)
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  componentDidMount() {
    const { schedule } = this.props
    // this.updateReceipt(schedule)
  }

  updateReceipt(schedule) {
    console.log(schedule);
    this.setState({
      formControls: {
        name: {
          value: schedule.name
        },
        date: {
          value: schedule.date
        },
        category: {
          value: schedule.category
        },
        amount: {
          value: schedule.amount
        }
      }
    })

    let completeness = 0
    if (schedule.category != 'TBD') {
      completeness = 100
    } else {
      completeness = 50
    }

    this.setState({completeness})
  }

  handleChange(event, name) {
    const value = event.target.value;

    console.log('name: ', name, ' value: ', value);

    this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: {
          ...this.state.formControls[name],
          value
        }
      }
    });
  };

  saveData() {
    const { schedule, saveDetails } = this.props;
    // console.log('saveData:', schedule);

    let updated = {}
    if (schedule.name != this.state.formControls.name.value) {
      Object.assign(updated, { name: schedule.name });
    }
    if (schedule.date != this.state.formControls.date.value) {
      Object.assign(updated, { date: schedule.date });
    }
    if (schedule.category != this.state.formControls.category.value) {
      Object.assign(updated, { category: schedule.category });
    }
    if (schedule.amount != this.state.formControls.amount.value) {
      Object.assign(updated, { amount: schedule.amount });
    }

    let newReceipt = {
      id: schedule.id,
      name: this.state.formControls.name.value,
      date: this.state.formControls.date.value,
      category: this.state.formControls.category.value,
      amount: this.state.formControls.amount.value,
      status: schedule.status,
      modified: updated
    }
    saveDetails(newReceipt, (updatedReceipt) => {
      // console.log('saveDeatils: ', updatedReceipt.schedule);
      this.updateReceipt(updatedReceipt.schedule)
    })
  }

  render() {
    const { classes, className, closeModal, saveDetails, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle=""
            title="Schedule"
          />
        </PortletHeader>
        <PortletContent noPadding>
          <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="contained"
            onClick={ () => this.saveData() }
          >
            Save
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={closeModal}
          >
            Close
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

ListEditor.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  saveDetails: PropTypes.func
};

ListEditor.defaultProps = {
  closeModal: () => {},
  saveDetails: () => {}
};

export default withStyles(styles)(ListEditor);
