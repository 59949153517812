export default theme => ({
  root: {},
  field: {
    margin: theme.spacing(3)
  },
  fieldCenter: {
    width: '90%',
    margin: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  nField: {
    display: 'flex',
    flexDirectoin: 'row',
    margin: theme.spacing(3)
  },
  numberField: {
    width: '220px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    selfAlign: 'center',
    alignItems: 'center'
  },
  dropzoneText: {
    fontFamily: 'Montserrat, sans-serif',
    textTransform: 'none',
  },
  portletButton: {
    flex: 1,
    textAlign: 'right',
    alignItems: 'right',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  portletFooter: {
    flex: 1,
    // width: 400,
    // backgroundColor: '#000',
    alignItems: 'center',
    // justifyContent: 'space-between', // 'flex-end',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  saveButton: {
    // flex: 0.1,
    // width: 100,
    float: 'right',
    justifyContent: 'flex-end',
    textAlign: 'end',
    alignItems: 'right',
    paddingRight: theme.spacing(3),
  },
  deleteButton: {
    // flex: 0.1,
    // width: 100,
    float: 'left',
    justifyContent: 'flex-start',
    textAlign: 'start',
    alignItems: 'left',
    paddingLeft: theme.spacing(3),
  }
});
