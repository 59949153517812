import React, { Component } from 'react';
import {DropzoneArea, DropzoneDialog} from 'material-ui-dropzone'

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, IconButton } from '@material-ui/core';

// Material icons
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';

// Shared components
import { DisplayMode, SearchInput } from 'components';

import axios from 'axios';

// Component styles
import styles from './styles';

class ReceiptsToolbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      files: [],
      showDropZone: false,
      open: false
    };
  }
  handleChange(files){
    this.setState({
      files: files
    });
  }
  handleClose() {
      this.setState({
          open: false
      });
  }
  handleSave(files) {
    //Saving files to state for further use and closing Modal.
    this.setState({
        files: files,
        open: false
    });

    const { onAdd } = this.props

    onAdd(files)
  }
  handleOpen() {
      this.setState({
          open: true,
      });
  }

  handleTest() {
    const API_URL = 'https://www.youtube.com/'

    const fetchOptions = {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }

    fetch(API_URL, fetchOptions).then(function (response) {
      // console.log('response', API_URL, response)
    }).then(function (json) {
      // console.log('json', json)
    })


    // fetch('https://www.youtube.com/feed/history', {
    //   mode: 'no-cors' // 'cors' by default
    // })
    // .then(function(response) {
    //   console.log("response", response);
    // });

    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    // axios.get('https://www.youtube.com/feed/history')
    //  .then( (response) => {
    //    console.log("response", response);
    //  })
    //  .catch( (error) => {
    //    console.log(error);
    //  });
  }

  handleDeleteReceipts() {
    const { onDelete } = this.props
    onDelete()
  }

  render() {
    const { classes, className, selectedReceipts } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <span className={classes.spacer} />
          {selectedReceipts.length > 0 && (
            <IconButton
              className={classes.deleteButton}
              onClick={this.handleDeleteReceipts.bind(this)}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <Button
            className={classes.importButton}
            size="small"
            variant="outlined"
            onClick={this.handleTest.bind(this)}
          >
            <ArrowDownwardIcon className={classes.importIcon} /> Import
          </Button>
          <Button
            className={classes.exportButton}
            size="small"
            variant="outlined"
            onClick={ () => { this.setState({showDropZone: !this.state.showDropZone}) }}
          >
            <ArrowUpwardIcon className={classes.exportIcon} />
            Export
          </Button>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={this.handleOpen.bind(this)}
          >
            Add
          </Button>
        </div>
        { this.state.showDropZone && (
          <DropzoneArea
            onChange={this.handleChange.bind(this)}
          />
        )}
        <DropzoneDialog
            open={this.state.open}
            onSave={this.handleSave.bind(this)}
            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
            showPreviews={true}
            maxFileSize={5000000}
            onClose={this.handleClose.bind(this)}
            showFileNamesInPreview={true}
        />

        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            placeholder="Search receipt"
          />
          <span className={classes.spacer} />
          <DisplayMode mode="list" />
        </div>
      </div>
    );
  }
}

ReceiptsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedReceipts: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func
};

ReceiptsToolbar.defaultProps = {
  selectedReceipts: [],
  onAdd: () => {},
  onDelete: () => {}
};

export default withStyles(styles)(ReceiptsToolbar);
