import React, { Component } from 'react';
import { compose } from 'recompose';
import './dnd.css'
// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, TextField } from '@material-ui/core';

// Material components
import {
  CircularProgress,
  Typography,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Button
} from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { addSchedule, getSchedules, getScheduleById, updateSchedule, updateScheduleCue, deleteSchedule } from 'services/schedule';

// Custom components
import { SchedulesToolbar, SchedulesTable } from './components';

// Component styles
import styles from './style';

// Authorization
import { withAuthorization } from '../../session';

// custom functions
import { uploadFiles } from 'rest'
// import Modal from 'react-modal';
import '@trendmicro/react-modal/dist/react-modal.css';
import Modal from '@trendmicro/react-modal';
import ImageEditor from '../Editor/components/ImageEditor';

import { Container, Draggable } from 'react-smooth-dnd';

import {
  DisplayMode,
  SearchInput,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// const tileData =
// [{
//         src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
//         thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
//         thumbnailWidth: 100,
//         thumbnailHeight: 100,
//         // isSelected: true,
//         caption: "After Rain (Jeshu John - designerspics.com)",
//         id: 0
// },
// {
//         src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
//         thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
//         thumbnailWidth: 100,
//         thumbnailHeight: 100,
//         tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
//         caption: "Boats (Jeshu John - designerspics.com)",
//         id: 1
// },
// {
//         src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
//         thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
//         thumbnailWidth: 100,
//         thumbnailHeight: 100,
//         id: 2
// }]

// const customStyles = {
//   overlay: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(200, 200, 200, 0.75)'
//   },
//   content: {
//     position: 'absolute',
//     top: '140px',
//     left: '140px',
//     right: '140px',
//     bottom: '140px',
//     border: '1px solid #ccc',
//     background: '#fff',
//     overflow: 'auto',
//     WebkitOverflowScrolling: 'touch',
//     borderRadius: '4px',
//     outline: 'none',
//     padding: '20px'
//   }
// };

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#root')

class Schedules extends Component {
  constructor() {
    super()
    this.signal = true;

    this.state = {
      isLoading: false,
      // items: tileData,
      limit: 10,
      email: '',
      schedules: [],
      schedule: null,
      scheduleTitle: '',
      scheduleEmail: '',
      cues: [],
      selectedSchedules: [],
      error: null,
      listModalOpen: false,
      editModalOpen: false,
      hasCuesOrderUpdated: false
    };

    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.closeListModal = this.closeListModal.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.loadSchedules = this.loadSchedules.bind(this);
  }

  closeListModal() {
    this.setState({listModalOpen: false});
    if (this.state.hasCuesOrderUpdated) {
      this.updateCuesOrder()
    }
    this.loadSchedules();
  }

  updateCuesOrder() {
    const { schedule, cues, email } = this.state
    // cue.id,
    // cue.title,
    // cue.duration,
    // cue.order,
    // let cue = {
    //   id: keys[i],
    //   order: cueObj.order,
    //   duration: cueObj.duration,
    //   caption: imageObj.title,
    //   thumbnail: imageObj.url,
    //   src: imageObj.url,
    //   thumbnailWidth: 200,
    //   thumbnailHeight: 200
    // }
    for (let i = 0; i < cues.length; i++) {
      let cue = {
        id: cues[i].id,
        order: cues[i].order,
        title: cues[i].caption,
        duration: cues[i].duration
      }
      updateScheduleCue(schedule, cue, email, (result) => {
        console.log('updateCuesOrder: ', result);
      })
    }
  }

  closeEditModal() {
    this.setState({editModalOpen: false});
  }

  openEditModal(image) {
    // console.log('openEditModal: ', image);
    this.setState({
      selectedImage: image,
      editModalOpen: true
    });
    // this.setState({editModalOpen: true});
  }

  async loadSchedules() {
    try {
      this.setState({ isLoading: true });

      const { limit, email } = this.state;

      // console.log('email in call ', email);

      const { schedules, schedulesTotal } =  await getSchedules(limit, email);

      // console.log('loadSchedules: ', schedules, schedulesTotal);

      if (this.signal) {
        this.setState({
          isLoading: false,
          schedules
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  componentDidMount() {
    this.signal = true;
    const email = this.props.firebase.auth.currentUser.email
    this.setState({email})

    var self = this
    setTimeout(function () {
      self.loadSchedules();
    }, 100);
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = selectedSchedules => {
    this.setState({ selectedSchedules });
    var self = this
    setTimeout(function () {
      // console.log('selectedSchedules: ', self.state.selectedSchedules);
    }, 100);
  };

  deleteSchedules() {
    this.setState({ isLoading: true });
    let email = this.state.email
    let ids = []
    for (let i = 0; i < this.state.selectedSchedules.length; i++) {
      ids.push(this.state.selectedSchedules[i])
    }
    for (let i = 0; i < ids.length; i++) {
      deleteSchedule(ids[i], email, (result) => {
        // console.log('deleteSchedules: ', result, email, ids[i]);
      })
    }
    this.setState({ selectedSchedules: [] })
    this.loadSchedules()
  }

  addNewSchedule(title) {
    let email = this.state.email

    this.setState({ isLoading: true });

    var self = this
    addSchedule(email, title, (result) => {
      // console.log('addNewSchedule: ', result);
      self.loadSchedules()
    })
  }

  getSortOrder(prop) {
    return function(a, b) {
      if (a[prop] > b[prop]) {
          return 1;
      } else if (a[prop] < b[prop]) {
          return -1;
      }
      return 0;
    }
  }

  afterSave() {
    this.closeEditModal();
    this.showDetails(this.state.schedule.id)
    console.log('callback from editor');
  }

  async showDetails(id) {
    const { schedule }  = await getScheduleById(id)

    let cues = []

    if (schedule.cues != null) {
      let keys = Object.keys(schedule.cues)

      let scheduleJson = JSON.stringify(schedule.cues)
      let scheduleObj = JSON.parse(scheduleJson)

      for (let i = 0; i < keys.length; i++) {
        // let scheduleJson = JSON.stringify(schedule.cues)
        // let scheduleObj = JSON.parse(scheduleJson)
        let cueJason = JSON.stringify(scheduleObj[keys[i]])
        let cueObj = JSON.parse(cueJason)
        let imageJson = JSON.stringify(cueObj.image)
        let imageObj = JSON.parse(imageJson)
        let cue = {
          id: keys[i],
          order: cueObj.order,
          duration: cueObj.duration,
          caption: imageObj.title,
          thumbnail: imageObj.url,
          src: imageObj.url,
          thumbnailWidth: 200,
          thumbnailHeight: 200
        }
        cues.push(cue)
      }

      cues.sort(this.getSortOrder('order'))
    }

    let scheduleJson = JSON.stringify(schedule)
    let scheduleObj = JSON.parse(scheduleJson)
    let scheduleTitle = scheduleObj.title
    let scheduleEmail = scheduleObj.email

    // console.log('showDetails: ', scheduleTitle);
    this.setState({listModalOpen: true, scheduleTitle, scheduleEmail, schedule, cues});
  }

  updateScheduleTitle() {
    this.closeListModal();

    const { schedule, scheduleTitle, email } = this.state
    this.setState({ isLoading: true });

    var self = this
    updateSchedule(schedule, scheduleTitle, email, (result) => {
      console.log('updateScheduleTitle: ', result);
      self.loadSchedules()
    })
  }

  async saveDetails(newSchedule, callback) {
    let email = this.state.email
    // console.log('saveDetails: ', newSchedule, email);
    let result = await updateSchedule(newSchedule, email)
    // console.log('result of saveDetails: ', result, newSchedule.id);
    let updatedSchedule = await getScheduleById(newSchedule.id)
    // console.log('updated: ', updatedSchedule);
    callback(updatedSchedule)
  }

  renderSchedules() {
    const { classes } = this.props;
    const { isLoading, schedules, error } = this.state;

    if (isLoading) { // need to be modal
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (schedules.length === 0) {
      return <Typography variant="h6">There are no schedules</Typography>;
    }

    return (
      <SchedulesTable
        onShowDetails={this.showDetails}
        onSelect={this.handleSelect}
        schedules={schedules}
        callback={this.loadSchedules}
      />
    );
  }

  applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    const { cues } = this.state
    for (let i = 0; i < result.length; i++) {
      let resultJson = JSON.parse(JSON.stringify(result[i]))
      // console.log(resultJson.id);
      for (let j = 0; j < cues.length; j++) {
        if (cues[j].id == resultJson.id) {
          // console.log(cues[j].order, i + 1);
          cues[j].order = i + 1
        }
      }
    }

    this.setState({ cues, hasCuesOrderUpdated: true })

    // console.log('applyDrag:', cues);

    return result;
  };

  handlescheduleTitleChange(event, name) {
    const value = event.target.value;

    // console.log('name: ', name, ' value: ', value);

    this.setState({
      scheduleTitle: value
    });
  };

  render() {
    const { classes } = this.props;
    const { email, scheduleTitle, scheduleEmail, schedule, selectedImage, cues, selectedSchedules } = this.state;
    let title = `Schedule - ${scheduleTitle}`
    let sharedBy = ` (shared by ${scheduleEmail})`
    title += email != scheduleEmail ? sharedBy : ''

    return (
      <DashboardLayout title="Schedules">
        <div className={classes.root}>

          <Modal
            show={this.state.listModalOpen}
            showOverlay={this.state.listModalOpen}
            onClose={this.closeListModal}
            className={classes.modal}
          >
            <Portlet
              // {...rest}
              // className={classes.portlet}
            >
              <PortletHeader>
                <PortletLabel
                  subtitle=""
                  title={title}
                />
              </PortletHeader>
              <PortletContent noPadding>
                <div className={classes.field}>
                  <TextField
                    disabled={email != scheduleEmail}
                    className={classes.textField}
                    helperText="Description for this schedule"
                    label="Title"
                    margin="dense"
                    onChange={(evt) => this.handlescheduleTitleChange(evt, 'title')}
                    required
                    value={scheduleTitle || ''}
                    variant="outlined"
                  />
                </div>
                <div className={classes.portletButton}>
                  <Button
                    disabled={email != scheduleEmail}
                    color="primary"
                    variant="outlined"
                    onClick={() => this.openEditModal()}
                  >
                    Add
                  </Button>
                </div>
                <div className="simple-page-scroller">
                {cues.length < 1 &&
                  <Typography variant="h6">There are no cues in this schedule yet.</Typography>
                }
                <Container
                  className={classes.groupStyle}
                  orientation="horizontal"
                  getChildPayload={i => cues[i]}
                  dragHandleSelector=".column-drag-handle"
                  onDrop={e => this.setState({ cues: this.applyDrag(cues, e) })}
                >
                  {cues.map((item) => {
                    return (
                      <Draggable key={item.id}>
                        <div className="draggable-item-horizontal">
                          <span className="column-drag-handle"
                            style={{float:'left', padding:'1 20px', marginLeft:'10px', fontSize:'25px'}}>
                            &#x2630;
                          </span>
                          <Button
                            disabled={email != scheduleEmail}
                            onClick={() => this.openEditModal(item)}
                          >
                            <img src={item.thumbnail}
                              style={{width: item.thumbnailWidth, height: item.thumbnailHeight}}
                              alt={item.caption} />
                          </Button>
                        </div>
                      </Draggable>
                    )
                  })}
                </Container>
                </div>
              </PortletContent>
              <PortletFooter className={classes.portletFooter}>
                <Button
                  disabled={email != scheduleEmail}
                  color="primary"
                  variant="contained"
                  onClick={() => this.updateScheduleTitle()}
                >
                  Save
                </Button>
              </PortletFooter>
            </Portlet>
          </Modal>

          <Modal
            show={this.state.editModalOpen}
            showOverlay={this.state.editModalOpen}
            onClose={this.closeEditModal}
          >
            <ImageEditor
              from={'schedule'}
              email={email}
              schedule={schedule}
              image={selectedImage}
              lastorder={cues.length + 1}
              onClose={this.closeEditModal}
              callback={this.afterSave.bind(this)} />
          </Modal>

          <SchedulesToolbar
            selectedSchedules={selectedSchedules}
            onAdd={this.addNewSchedule.bind(this)}
            onDelete={this.deleteSchedules.bind(this)} />

          <div className={classes.content}>{this.renderSchedules()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

Schedules.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withStyles(styles))(Schedules);
